import { createContext, useEffect, useState } from 'react';
import { getSocialMediaTemplateGroupModelByUuid } from "../../../services/social_media";
import useEditorUseContext from '../hooks/useEditorUseContext';
import { loadVideoEditorAssets } from '../../../utils/video';
import { loadTemplateFonts } from '../../../utils/fonts';
import useDesignEditorContext from '../hooks/useDesignEditorContext';
import { useEditor } from '@layerhub-io/react';
import useAppContext from '../hooks/useAppContext';
import { PanelType } from '../../../constants/app-options';


export const TemplateGroupModelContext = createContext<any>(null);

export const TemplateGroupModelProvider = (props: any) => {
    const { use, setCanvasClassNames } = useEditorUseContext();
    const children = props.children;
    const [templateGroupModel, setTemplateGroupModel] = useState(null);
    const modelUuid = props?.uuid;
    const {setScenes, setCurrentDesign, currentDesign} = useDesignEditorContext();
    const editor = useEditor();
    const { setActivePanel } = useAppContext();

    useEffect(() => {
        if (use !== "tg_model") return;
        setCanvasClassNames({
			previewWrapper: 'h-100',
			canvasWrapper: 'h-100'
		})
        setActivePanel(PanelType.TEXT);
        getSocialMediaTemplateGroupModelByUuid(modelUuid).then(modelData => {
            setTemplateGroupModel(modelData)

            const loadGraphicTemplate = async (payload: any) => {
				const scenes = []
				const { scenes: scns, ...design } = payload
			
				for (const scn of scns) {
                    const scene: any = {
                        name: scn.name,
                        frame: payload.frame,
                        id: scn.id,
                        layers: scn.layers,
                        metadata: {},
                    }
                    const loadedScene = await loadVideoEditorAssets(scene)
                    await loadTemplateFonts(loadedScene)
                
                    // const preview = (await editor.renderer.render(loadedScene)) as string
                    const preview = null;
                    scenes.push({ ...loadedScene, preview })
				}
			
				return { scenes, design }
			}
			const handleImportTemplate = async (data: any) => {
				let template
				if (data.type === "GRAPHIC") {
					template = await loadGraphicTemplate(data)
				}
				//   @ts-ignore
				setScenes(template.scenes)
				//   @ts-ignore
				setCurrentDesign(template.design)
			}
            const applyResize = async (width : number, height: number) => {
                if (editor) {
                    editor.frame.resize({
                        width : width,
                        height : height,
                    })
                    setCurrentDesign({
                        ...currentDesign,
                        frame: {
                            width: width,
                            height: height
                        }
                    })
                }
            }

            if (modelData?.sceneJson) handleImportTemplate(modelData.sceneJson);
            else {
                const postType = modelData?.postType;
                if (postType) {
                    const size = postType.size;
                    applyResize(size.width, size.height)
                }
            }
        });
    }, [modelUuid, use, setCurrentDesign, setScenes, editor]);

    const context = {
        templateGroupModel,
        setTemplateGroupModel,
        modelUuid
    };

    return <TemplateGroupModelContext.Provider value={context}>{children}</TemplateGroupModelContext.Provider>
}