import { useState, useEffect } from "react";
import { Link} from "react-router-dom";
import Image from "react-bootstrap/Image";
import { useTranslation } from "react-i18next";
import { getAuthTokenWithRefresh, getBackendApiUrl, getEasyPlvApiToken } from "../../config";
import ListGroup from 'react-bootstrap/ListGroup';
import { NwLoaderSpinner } from "../../components/_utilityComponents/NwIcons/NwIcons";
import { useMainLayoutContext } from "../../hooks/useMainLayoutContext";
import { PlatformTypes } from "../../constants/nw/nw-constants";

const DashboardMessagesList = ({}) => {
	const { t } = useTranslation();
	const [posts, setPosts] = useState([]); 
	const [pageCount, setPageCount] = useState(0);
	const [totalItems,setTotalItems] = useState(0);
  	const [loading, setLoading] = useState(true);
    const apiKey = getEasyPlvApiToken();
	const { currentPlatform } = useMainLayoutContext();

	async function getMessages() {
		try {
			const authToken = await getAuthTokenWithRefresh();
			const response = await fetch(getBackendApiUrl("messages?viewDashboard=1"), {
				method: "GET",
				headers: {
					Authorization: `Bearer ${authToken}`,
					"X-EasyPLV-Api-Token": apiKey,
				},
			});
			if (response.status === 401) {
				return false;
			}
			const data = await response.json();
			setPosts(data.items);
			setTotalItems(data.totalMessages);
			setPageCount(Math.ceil(data.totalMessages / 5));

			setLoading(false);

		} catch (error) {
			console.error("Error fetching data: ", error);
			// Set loading state to false in case of an error
			setLoading(false);
		}
	}

    
    useEffect(() => {
    	getMessages();    
	}, []);


	return (
		<div className="rounded-4 shadow-lg h-100 bg-white p-3 d-flex flex-column messages-list-section-inner-wrapper">
			<div
				className="mb-xxl-2 "
				style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "space-between",
				}}
			>
				<span className="nw-responsive-font-size ps-2 fw-bold">
					{t("sideBarLabels.messages")}
					{loading ? (<NwLoaderSpinner isSpinner size={'1em'} className="ms-1 nw-responsive-font-size"/>) : null}
				</span>
				<Link
					to={'/messages'}
					className=" text-decoration-none text-primary fw-bold nw-responsive-font-size rounded-pill d-flex align-items-center fw-semibold py-0 px-0"
					style={{
						width: 'fit-content',
						height: 'fit-content'
					}}
				>
						{t("dashboard.messages.viewAll")}
				</Link>
			</div>
		
			<ListGroup className="d-flex flex-column flex-grow-1 overflow-auto messages-section-list-wrapper" variant="flush">
				{posts?.map((post, index) => (
					<ListGroup.Item key={index} className="d-flex align-items-center messages-section-list-item-container">
						<Link to={`view/message/${post.id}`} className="w-100 d-flex messages-section-list-item-wrapper align-items-center text-decoration-none text-dark">
							<Image
								className="messages-section-list-item-img d-none"
								src="https://bootdey.com/img/Content/avatar/avatar6.png"
								roundedCircle
								style={{
									width: '5%',
									borderRadius: '50%',
									marginRight: '15px'
								}}
							/>
							<div className="nw-responsive-font-size-md messages-section-list-item-content" style={{ flex: 1 }}>
								<div className="d-flex justify-content-between align-items-center messages-section-list-item-content-header">
									<div className="fw-semibold">{post.subject}</div>
									<div style={{ float: 'right' }}>{post.createdAtHumanReadable}</div>
								</div>
								<div className={`nw-text-placeholder-color-2 messages-section-list-item-content-body nw-text-prewrap ${currentPlatform == PlatformTypes.IS_SMALL_DESKTOP ? 'nw-text-truncate-2' : 'nw-text-truncate-3'}`}>{post.body}</div>
							</div>
						</Link>
					</ListGroup.Item>
				))}
			</ListGroup>
	
		</div>
	);
};

export default DashboardMessagesList;
