import { useState, useEffect } from "react"
import { filterDailyNotifications, getDailyNotifications, seeNotification } from "../../services/notification";
import ReactPaginate from "react-paginate";
import { Table, Button, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { EyeSlashFill, Eye } from "react-bootstrap-icons";
import { NwLoaderSpinner } from "../../components/_utilityComponents/NwIcons/NwIcons";

const Notifications = () => {

    const [notifications, setNotifications] = useState([]);
    const [totalNotifications, setTotalNotifications] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [pageCount, setPageCount] = useState(0);
    const [viewingNotifs, setViewingNotifs] = useState({});
    const {t} = useTranslation();

    const handlePaginationChange = async (pageData) => {
        const selectedPage = pageData?.selected ? pageData.selected : 0;
        const filters = {
            "page": selectedPage + 1
        };

        const filteredDailyNotifications = await filterDailyNotifications(filters);
        setNotifications(filteredDailyNotifications?.notifications ? filteredDailyNotifications.notifications : []);
    }

    const viewNotification = async (notificationId) => {
        setViewingNotifs(prevViewingNotifs => {
            const newViewingNotifs = {...prevViewingNotifs};
            newViewingNotifs[notificationId] = true;
            return newViewingNotifs;
        })

        const seen = await seeNotification(notificationId);
        if (seen) {
            window.location.reload();
        }
    }

    useEffect(() => {
        getDailyNotifications().then(response => {
            setTotalNotifications(response ? response.totalNotifications : 0);
            let notifications = response ? response?.notifications : [];
            notifications = notifications.map(notification => {
                return {...notification, body: t(notification.body), subject: t(notification.subject)}
            })
            const notificationsViewingById = {};
            notifications.forEach(notification => {
                notificationsViewingById[notification.id] = false;
            });
            setViewingNotifs(notificationsViewingById);
            setNotifications(notifications);
            setPageCount(response ? response.totalNotifications / itemsPerPage : 0);
        })
    }, [itemsPerPage, t])

    return (
        <>
            <div className="px-5 pt-5">
                <div className="container mt-5">
                    {
                        Object.keys(notifications)?.length > 0 ? (  
                            <Table bordered hover>
                                <thead>
                                <tr>
                                    <th></th>
                                    <th>Date</th>
                                    <th>{t("messagesPage.messagesTable.subjectTableHeading")}</th>
                                    <th>{t("messagesPage.messagesTable.contentHeading")}</th>
                                    <th></th>
                                </tr>
                                </thead>
                                <tbody>
                                {(notifications).map((item) => {
                                    return (
                                        <tr key={item.id} className={item?.seen ? "" : "table-success"}>
                                            <td align="center" className="nw-responsive-font-size">{item?.seen ? <Eye className="text-success"/> : <EyeSlashFill className="text-danger"/>}</td>
                                            <td>{item?.createdAt ? (new Date(item.createdAt)).toLocaleString() : "-"}</td>
                                            <td>{item.subject}</td>
                                            <td>{item.body}</td>
                                            <td align="center">
												<Button 
													className="rounded-pill w-100 h-100 nw-bg-accent-1 flex-shrink-0 border-0 nw-responsive-font-size d-flex justify-content-center align-items-center" 
													onClick={() => viewNotification(item?.id)}
												>
													{viewingNotifs[item?.id] ? (
															<NwLoaderSpinner isSpinner size={'1em'}/>
														) : (
															<span>{t("notificationsPage.notificationsTable.viewNotificationButtonLabel")}</span>
														)
													}
												</Button>
											</td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </Table>
                        ) : (
                            <p>{t("messagesPage.No_messages_available")}</p>
                        )
                    }

                    {
                        totalNotifications > itemsPerPage ? 
                        <ReactPaginate
                            previousLabel={"previous"}
                            nextLabel={"next"}
                            breakLabel={"..."}
                            pageCount={pageCount}
                            // forcePage={currentPagePaginate}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={3}
                            onPageChange={handlePaginationChange}
                            containerClassName={"pagination justify-content-center"}
                            pageClassName={"page-item"}
                            pageLinkClassName={"page-link"}
                            previousClassName={"page-item"}
                            previousLinkClassName={"page-link"}
                            nextClassName={"page-item"}
                            nextLinkClassName={"page-link"}
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                            activeClassName={"active"}
                        /> : <></>
                    }
                </div>
            </div>
        </>
    )
}

export default Notifications;