import Panels from "./components/Panels"
import Common from "./components/Footer/Graphic/Common"
import Footer from "./components/Footer"
import Toolbox from "./components/Toolbox"
import EditorContainer from "./components/EditorContainer"
import { TemplateGroupProvider } from "./context/TemplateGroup"
import { useParams } from "react-router-dom"
import { EditorUseProvider } from './context/EditorUse'
import EditorContent from './components/EditorContent';
import { SocialMediaProvider } from './context/SocialMedia';
import { TemplateGroupModelProvider } from './context/TemplateGroupModel';
import { TimerProvider } from "@layerhub-io/use-timer"
import { Provider as ScenifyProvider } from "@layerhub-io/react"
import { Client as Styletron } from "styletron-engine-atomic"
import { Provider as StyletronProvider } from "styletron-react"
import { BaseProvider, LightTheme } from "baseui"
import { store } from "./store/store"
import { Provider as ReduxProvier } from "react-redux"
import { AppProvider } from "./contexts/AppContext"
import { DesignEditorProvider } from "./contexts/DesignEditor"
import { useMainLayoutContext } from "../../hooks/useMainLayoutContext"
import { useEffect } from "react"

const engine = new Styletron();

const FabricGraphicEditor = (props) => {
	const params = useParams();
	const usage = props.usage;
	const mode = params.mode;

    const { setShowFooter } = useMainLayoutContext();

    useEffect(() => {
        setShowFooter(false);
    }, []);

	return (
        <ReduxProvier store={store}>
            <DesignEditorProvider>
                <TimerProvider>
                    <AppProvider>
                        <ScenifyProvider>
                            <StyletronProvider value={engine}>
                                <BaseProvider theme={LightTheme}>
                                    <EditorContainer>
                                        <EditorUseProvider usage={usage}>      
                                            <TemplateGroupProvider mode={mode} freePosterId={params.freePosterId}>
                                                <SocialMediaProvider mode={mode} entity={params.entity} id={params.id}>
                                                    <TemplateGroupModelProvider uuid={params?.uuid}>
                                                        <div 
                                                            className="nw-free-poster-main" 
                                                            style={{ 
                                                                display: "flex",
                                                                height: '93svh'
                                                            }}
                                                        >
                                                            <Panels />
                                                            <div style={{ flex: 1, display: "flex", flexDirection: "column", position: "relative" }}>
                                                                <Common />
                                                                <Toolbox />
                                                                <EditorContent />
                                                                <Footer />
                                                            </div>
                                                        </div>
                                                    </TemplateGroupModelProvider>
                                                </SocialMediaProvider>
                                            </TemplateGroupProvider>
                                        </EditorUseProvider>
                                    </EditorContainer>
                                </BaseProvider>
                            </StyletronProvider>
                        </ScenifyProvider>
                    </AppProvider>
                </TimerProvider>
            </DesignEditorProvider>
        </ReduxProvier>
	)
}

export default FabricGraphicEditor
