import { deletePrinterById, findAllAvailablePrinters, getAllFormatsForPrintersCrud, getConfiguredPrinters, savePrinterFromQz, updateConfiguredPrinter, updateStorePrintServerIp } from "../../services/print";
import { useState, useEffect } from "react";
import { Button, Form, Container } from "react-bootstrap";
import { ListGroup } from "react-bootstrap";
import Select from "react-select";
import { getStoreDataFromLocalStorage } from "../../services/store";
import { useTranslation } from "react-i18next";
import { printPdfUsingPrinter } from "../../services/print";
import {
	OverlayTrigger,
	Tooltip
} from "react-bootstrap";
import {CircleFill} from 'react-bootstrap-icons';



function PrintersPage () {
    const { i18n, t } = useTranslation();
    const [qzTrayPrinters, setQzTrayPrinters] = useState(null);
    const [availablePrinters, setAvailablePrinters] = useState(null);
    const [configuredPrinters, setConfiguredPrinters] = useState(null);
    const [printerServerIp, setPrinterServerIp] = useState("");
    const [fetchedFormats, setFetchedFormats] = useState("");
    const testPrintPdfUrl = "https://d3uyaodvmtecq4.cloudfront.net/DEFAULT_DO_NOT_DELETE/test_print/test_print.pdf?time=" + Date.now()


    const formatConfiguredPrinters = (fetchedConfiguredPrinters, formats) => {
        try {
            let companyFormats = formats['companyFormats'];
            let sourceFormats = formats['sourceFormats'];
            let printersConfigured = {};
            fetchedConfiguredPrinters.forEach(printer => {
                printersConfigured[printer.name] = {
                    printerId : printer.id,
                    printerName: printer.name,
                    enable : Boolean(printer.enabled),
                    companyFormats : companyFormats,
                    sourceFormats : sourceFormats,
                    selectedCompanyFormats : printer.outputFormat.map(format => {
                        return {
                            'formatId' : format.id,
                            'formatName' : format.name,
                            'label' : format.name,
                            'value' : format.id
                        }
                    }),
                    selectedSourceFormats : printer.sourceFormat.map(format => {
                        return {
                            'formatId' : format.id,
                            'formatName' : format.name,
                            'label' : format.name,
                            'value' : format.id
                        }
                    }),
                    displayName: printer.displayName
                } 
            });

            return printersConfigured;
        } catch (e) {console.log(e); return [];}
    }

    const formatAvailablePrinters = (availablePrinters, formats) => {
        try {
            let companyFormats = formats['companyFormats'];
            let sourceFormats = formats['sourceFormats'];
            let availablePrintersQz = {};
            availablePrinters.forEach(printer => {
                availablePrintersQz[printer] = {
                    printerName : printer,
                    enable: false,
                    companyFormats : companyFormats,
                    sourceFormats : sourceFormats,
                    selectedCompanyFormats : [],
                    selectedSourceFormats : [],
                    displayName: "",
                }
            });

            return availablePrintersQz;
        } catch (e) {console.log(e); return [];}
    }

    const removeConfiguredPrinterFromQzTrayPrinters = (configuredPrinterNames, qzTrayPrinterNames) => {
        try {
            configuredPrinterNames.forEach(configuredPrinterName => {
                const index =qzTrayPrinterNames.indexOf(configuredPrinterName);
                if (index > -1) { // only splice array when item is found
                    qzTrayPrinterNames.splice(index, 1); // 2nd parameter means remove one item only
                }
            });
        } catch (e) {console.log(e); return [];}

        return qzTrayPrinterNames;
    }

    const handlePrinterFormatChange = (newValue, actionMeta, printerName, printerSource) => {
        if (printerSource === "qz") {
            setAvailablePrinters(prevAvailablePrinters => {
                const newAvailablePrinters = {...prevAvailablePrinters};
                newAvailablePrinters[printerName].selectedCompanyFormats = newValue;
                return newAvailablePrinters;
            })
        } else if (printerSource === "configured") {
            setConfiguredPrinters(prevConfiguredPrinters => {
                const newConfiguredPrinters = {...prevConfiguredPrinters};
                newConfiguredPrinters[printerName].selectedCompanyFormats = newValue;
                return newConfiguredPrinters;
            })
        }
    } 

    const handlePrinterDisplayNameChange = (newValue, printerName, printerSource) => {
        if (printerSource === "qz") {
            setAvailablePrinters(prevAvailablePrinters => {
                const newAvailablePrinters = {...prevAvailablePrinters};
                newAvailablePrinters[printerName].displayName = newValue;
                return newAvailablePrinters;
            })
        } else if (printerSource === "configured") {
            setConfiguredPrinters(prevConfiguredPrinters => {
                const newConfiguredPrinters = {...prevConfiguredPrinters};
                newConfiguredPrinters[printerName].displayName = newValue;
                return newConfiguredPrinters;
            })
        }
    }

    const handlePrinterSourceFormatChange = (newValue, actionMeta, printerName, printerSource) => {
        if (printerSource === "qz") {
            setAvailablePrinters(prevAvailablePrinters => {
                const newAvailablePrinters = {...prevAvailablePrinters};
                newAvailablePrinters[printerName].selectedSourceFormats = newValue;
                return newAvailablePrinters;
            })
        } else if (printerSource === "configured") {
            setConfiguredPrinters(prevConfiguredPrinters => {
                const newConfiguredPrinters = {...prevConfiguredPrinters};
                newConfiguredPrinters[printerName].selectedSourceFormats = newValue;
                return newConfiguredPrinters;
            })
        }
    }

    const getAndUpdateConfiguredPrinters = async () => {
        try {
            let fetchedConfiguredPrinters = await getConfiguredPrinters();
            const formattedConfiguredPrinters = formatConfiguredPrinters(fetchedConfiguredPrinters, fetchedFormats);
            setConfiguredPrinters(formattedConfiguredPrinters);
        } catch (e) {console.log(e)}
    }

    const saveNewPrinter = async (printerName) => {
        await savePrinterFromQz(availablePrinters[printerName]);
        updateBothConfiguredAndAvailablePrinters();
    }

    const editConfiguredPrinter = async (printerName) => {
        await updateConfiguredPrinter(configuredPrinters[printerName]);
        getAndUpdateConfiguredPrinters();
    }

    const saveNewPrinterServerIp = async () => {
        await updateStorePrintServerIp(printerServerIp);
        window.location.reload();
    }

    const removeConfiguredPrinter = async (printerId) => {
        await deletePrinterById(printerId);
        updateBothConfiguredAndAvailablePrinters();
        return;
    }

    const printerToggleActive = async (e, printerName, printerSource) => {
        if (printerSource === "qz") {
            setAvailablePrinters(prevAvailablePrinters => {
                const newAvailablePrinters = {...prevAvailablePrinters};
                newAvailablePrinters[printerName].enable = e.target.checked;
                return newAvailablePrinters;
            })
        } else if (printerSource === "configured") {
            setConfiguredPrinters(prevConfiguredPrinters => {
                const newConfiguredPrinters = {...prevConfiguredPrinters};
                newConfiguredPrinters[printerName].enable = e.target.checked;
                return newConfiguredPrinters;
            })
        }
    }

    const updateBothConfiguredAndAvailablePrinters = async () => {
        try {
            let fetchedConfiguredPrinters = await getConfiguredPrinters();
            const newConfiguredPrinterNames = fetchedConfiguredPrinters.map(configuredPrinter => configuredPrinter.name);
            const availableQzTrayPrinters = removeConfiguredPrinterFromQzTrayPrinters(newConfiguredPrinterNames, [...qzTrayPrinters]);
            const formattedAvailableQzTrayPrinters = formatAvailablePrinters(availableQzTrayPrinters, fetchedFormats);
            const formattedConfiguredPrinters = formatConfiguredPrinters(fetchedConfiguredPrinters, fetchedFormats);
            setConfiguredPrinters(formattedConfiguredPrinters);
            setAvailablePrinters(formattedAvailableQzTrayPrinters);     
        } catch (e) {console.log(e);}
    }

    useEffect(() => {
        const getAllFormats = async () => {
            const formats = {
                'companyFormats' : [],
                'sourceFormats' : [],
            }
            let allFormats = await getAllFormatsForPrintersCrud();
            if (!allFormats || allFormats.length === 0) {
                setFetchedFormats(formats);
                return formats;
            }
            let companyFormats = allFormats.companyFormats.map((format) => {
                return {
                    'formatId' : format.formatId,
                    'formatName' : format.formatName,
                    'label' : format.formatName,
                    'value' : format.formatId
                }
            });
            let sourceFormats = allFormats.printerSourceFormats.map((format) => {
                return {
                    'formatId' : format.formatId,
                    'formatName' : format.formatName,
                    'label' : format.formatName,
                    'value' : format.formatId
                }
            });
            formats['companyFormats'] = companyFormats;
            formats['sourceFormats'] = sourceFormats;
            setFetchedFormats(formats);
            return formats;
        }

        const getAllPrinters = async (formats) => {
            try {
                let printersFromQzTray = await findAllAvailablePrinters();
                setQzTrayPrinters(printersFromQzTray);
                let configuredPrinters = await getConfiguredPrinters();
                // remove already configured printers from printers from qz tray
                const configuredPrinterNames = configuredPrinters.map(configuredPrinter => configuredPrinter.name);
                const availablePrintersFromQzTray = removeConfiguredPrinterFromQzTrayPrinters(configuredPrinterNames, [...printersFromQzTray]);
                const formattedAvailableQzTrayPrinters = formatAvailablePrinters(availablePrintersFromQzTray, formats);
                const formattedConfiguredPrinters = formatConfiguredPrinters(configuredPrinters, formats);
                setAvailablePrinters(formattedAvailableQzTrayPrinters);
                setConfiguredPrinters(formattedConfiguredPrinters);
            } catch (e) {console.log(e); setAvailablePrinters({})};
        }

        const storeData = getStoreDataFromLocalStorage();
        const printServerIp = storeData.printServerIp;
        setPrinterServerIp(printServerIp);
        getAllFormats().then((allFormats) => {getAllPrinters(allFormats);});
    }, []);


    return (
        
        <>
        <Container>
            <Form.Label htmlFor="inputPassword5">{t("printersPage.topSection.textBoxTitle")}</Form.Label>
            <Form.Control
                type="text"
                id="printerServerIp"
                aria-describedby="printerServerIpHelpBlock"
                value={printerServerIp}
                onChange={(e)=>setPrinterServerIp(e.target.value)}
            />
            <Form.Text id="printerServerIpHelpBlock" muted>
            {t("printersPage.topSection.belowTextBoxLabel")}
            </Form.Text>
            <br></br>
            <br></br>
            <Button onClick={(saveNewPrinterServerIp)}>{t("printersPage.topSection.saveButtonLabel")}</Button>
        <hr></hr>
        <h1>{t("printersPage.title1")}</h1>

        {
            availablePrinters &&
            <ListGroup>
                {
                    Object.keys(availablePrinters).map((printerName) => {
                        const printer = availablePrinters[printerName];
                        return (
                            <ListGroup.Item key={printerName}>
                                <div>
                                    <span><strong>{printer.displayName}</strong>({printer.printerName})</span>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip className="nw-tooltip">
                                                {printer.printerName} <span className="text-success fw-bold text-uppercase">online</span>
                                            </Tooltip>
                                        }
                                    >	
                                        <CircleFill className="text-success" size={10}/>
                                    </OverlayTrigger>
                                </div>
                                <br></br>
                                <div className="d-flex flex-row w-100">
                                    <div className="w-100 m-2">
                                        {t("Display name")}
                                        <input type="text" onChange={(e) => {
                                            handlePrinterDisplayNameChange(e.target.value, printerName, 'qz');
                                        }}  value={printer.displayName}/>
                                    </div>
                                    <div className="w-100 m-2">
                                        {t("printersPage.printerForm.formatTextBoxLabel")}
                                        <Select value={printer.selectedCompanyFormats} onChange={(newValue, actionMeta)=>handlePrinterFormatChange(newValue, actionMeta, printerName, "qz")} options={printer.companyFormats} isMulti={true} />
                                    </div>
                                    <div className="w-100 m-2">
                                        {t("printersPage.printerForm.sourceFormatsTextBoxLabel")}
                                        <Select value={printer.selectedSourceFormats} onChange={(newValue, actionMeta)=>handlePrinterSourceFormatChange(newValue, actionMeta, printerName, "qz")} options={printer.sourceFormats} isMulti={true} />
                                    </div>
                                    <div className="m-2">
                                        Active
                                        <Form.Check checked={printer.enable}  type={'checkbox'} onChange={(e)=>printerToggleActive(e, printerName, "qz")}/>
                                    </div>

                                </div>
                                <div>
                                    <Button className="m-2" onClick={()=>saveNewPrinter(printerName)}>ADD</Button>
                                    <Button onClick={() => {printPdfUsingPrinter(testPrintPdfUrl, printerName, 1, t("print.fail"))}}>Test Print</Button>
                                </div>
                            </ListGroup.Item>
                        )
                    })
                }
            </ListGroup>
            
        }
        <h1>{t("printersPage.title2")}</h1>
        {
            configuredPrinters  &&
            <ListGroup>
                {
                    Object.keys(configuredPrinters).map((printerName) => {
                        
                        const printer = configuredPrinters[printerName];
                        return (
                            <ListGroup.Item key={printerName}>
                                <div>
                                     <span><strong>{printer.displayName}</strong>({printer.printerName})</span>
                                    {
                                        <OverlayTrigger
                                         placement="top"
                                         overlay={
                                             <Tooltip className="nw-tooltip">
                                                 {printer.printerName} <span className={"fw-bold text-uppercase " + qzTrayPrinters.includes(printer.printerName) ? "text-success": "text-danger"}>{qzTrayPrinters.includes(printer.printerName) ? "online": "offline"}</span>
                                             </Tooltip>
                                         }
                                     >	
                                         <CircleFill className={qzTrayPrinters.includes(printer.printerName) ? "text-success": "text-danger"} size={10}/>
                                     </OverlayTrigger>
                                    }
                                </div>
                                <br></br>
                                <div className="d-flex flex-row w-100">
                                    <div className="w-100 m-2">
                                    {t("Display name")}
                                        <input type="text" onChange={(e) => {
                                            handlePrinterDisplayNameChange(e.target.value, printerName, 'configured');
                                        }} value={printer.displayName} />
                                    </div>
                                    <div className="w-100 m-2">
                                        {t("printersPage.printerForm.formatTextBoxLabel")}
                                        <Select value={printer.selectedCompanyFormats} onChange={(newValue, actionMeta)=>handlePrinterFormatChange(newValue, actionMeta, printerName, "configured")} options={printer.companyFormats} isMulti={true} />
                                    </div>
                                    <div className="w-100 m-2">
                                        {t("printersPage.printerForm.sourceFormatsTextBoxLabel")}
                                        <Select value={printer.selectedSourceFormats} onChange={(newValue, actionMeta)=>handlePrinterSourceFormatChange(newValue, actionMeta, printerName, "configured")} options={printer.sourceFormats} isMulti={true} />
                                    </div>
                                    <div className="m-2">
                                        {t("printersPage.printerForm.activeCheckBox")}
                                        <Form.Check checked={printer.enable} type={'checkbox'} onChange={(e)=>printerToggleActive(e, printerName, "configured")}/>
                                    </div>
                                </div>
                                <div>
                                    <Button className="m-1" onClick={()=>editConfiguredPrinter(printerName)}>{t("saveButtonLabel")}</Button>
                                    <Button className="m-1" variant={'danger'} onClick={()=>removeConfiguredPrinter(printer.printerId)}>{t("removeButtonLabel")}</Button>
                                    <Button className="m-1" onClick={() => printPdfUsingPrinter(testPrintPdfUrl, printerName, 1, t("print.fail"))}>Test Print</Button>
                                </div>
                            </ListGroup.Item>
                        )
                    })
                }
            </ListGroup>
        }
        </Container>
        </>
    )
}

export default PrintersPage;