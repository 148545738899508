function InformationCircleOutline({ size }: { size: number }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height={size} viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 12C3 7.02991 7.02991 3 12 3C16.9701 3 21 7.02991 21 12C21 16.9701 16.9701 21 12 21C7.02991 21 3 16.9701 3 12ZM12 4.44C7.8252 4.44 4.44 7.8252 4.44 12C4.44 16.1748 7.8252 19.56 12 19.56C16.1748 19.56 19.56 16.1748 19.56 12C19.56 7.8252 16.1748 4.44 12 4.44Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.02 10.74C10.02 10.3424 10.3424 10.02 10.74 10.02H12.18C12.5776 10.02 12.9 10.3424 12.9 10.74V15.96C12.9 16.3576 12.5776 16.68 12.18 16.68C11.7824 16.68 11.46 16.3576 11.46 15.96V11.46H10.74C10.3424 11.46 10.02 11.1376 10.02 10.74Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.48 16.14C9.48 15.7424 9.80236 15.42 10.2 15.42H14.16C14.5576 15.42 14.88 15.7424 14.88 16.14C14.88 16.5376 14.5576 16.86 14.16 16.86H10.2C9.80236 16.86 9.48 16.5376 9.48 16.14Z"
        fill="currentColor"
      />
      <path
        d="M12 6.69C11.7686 6.69 11.5424 6.75862 11.35 6.88718C11.1576 7.01574 11.0076 7.19847 10.9191 7.41226C10.8305 7.62605 10.8073 7.8613 10.8525 8.08826C10.8976 8.31521 11.0091 8.52369 11.1727 8.68732C11.3363 8.85094 11.5448 8.96237 11.7717 9.00752C11.9987 9.05266 12.234 9.02949 12.4477 8.94094C12.6615 8.85239 12.8443 8.70242 12.9728 8.51002C13.1014 8.31761 13.17 8.0914 13.17 7.86C13.17 7.5497 13.0467 7.2521 12.8273 7.03269C12.6079 6.81327 12.3103 6.69 12 6.69Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default InformationCircleOutline
