import React from "react"
import { Canvas as LayerhubCanvas } from "@layerhub-io/react"
import Playback from "../Playback"
import useDesignEditorContext from "../../hooks/useDesignEditorContext"
import ContextMenu from "../ContextMenu"
import Graphic from "../Preview/Graphic"
import { useTranslation } from "react-i18next"
import useEditorUseContext from "../../hooks/useEditorUseContext"

export type NwCanvasElementStyles = {
	container?: React.CSSProperties;
	previewWrapper?: React.CSSProperties;
	canvasWrapper?: React.CSSProperties;
}

export type NwCanvasElementClassNames = {
	container?: string;
	previewWrapper?: string;
	canvasWrapper?: string;
}

type NwCanvasProps = {
	hideCanvas?: boolean;
	classNames?: NwCanvasElementClassNames;
	styles?: NwCanvasElementStyles;
}

const Canvas: React.FC<NwCanvasProps> = ({
	hideCanvas = false,
	classNames,
	styles
}) => {
	const { displayPlayback, currentScene } = useDesignEditorContext();
	const { use } = useEditorUseContext();
	const { t } = useTranslation();

	return (
		<div 
			className={`nw-free-editor-canvas-container ${ classNames?.container ?? '' }`}
			style={{ 
				flex: 1,
				height: styles?.container?.height ?? hideCanvas ? "0%" : "100%",
				overflow: "hidden",
				position: "relative",
				...styles?.container
			}}
		>
			{displayPlayback && <Playback />}
			<ContextMenu />
			<div 
				className={`nw-free-editor-canvas-preview-wrapper w-100 d-flex ${ classNames?.previewWrapper ?? '' }`}
				style={{
					...styles?.previewWrapper
				}}
			>
				<div 
					className={`nw-free-editor-canvas-wrapper d-flex ${ classNames?.canvasWrapper ?? '' } ${ use == 'social_media' ? 'is-social-media' : ''}`}
					style={{
						...styles?.canvasWrapper
					}}
				>
					<LayerhubCanvas
						config={{
							background: "#f1f2f6",
							controlsPosition: {
								rotation: "BOTTOM",
							},
							shadow: {
								blur: 4,
								color: "#fcfcfc",
								offsetX: 0,
								offsetY: 0,
							},
							
						}}
					/>
				</div>

				{  
					use == 'social_media' ? (
						<div className="nw-free-editor-preview-wrapper h-100 p-4">
							<div className="preview-container shadow d-flex flex-column p-3">
								<div className="nw-responsive-font-size d-flex justify-content-center w-100">
									{t('freeEditorSocialMedia.common.preview')}
								</div>
								<div className="nw-free-editor-social-media-preview-platform-title w-100 d-flex justify-content-center align-items-center">
									<div className="nw-free-editor-social-media-preview-title-wrapper d-flex justify-content-center align-content-center bg-nw-primary rounded-pill text-white">
									{ currentScene?.platform?.title }
									</div>							
								</div>
								<Graphic
									imgOverlay={{
										header: <img className={`w-100 object-fit-fill ${ currentScene?.postType?.otherConfiguration?.header?.isOverlay ? 'position-absolute top-0' : '' }`} src={currentScene?.postType?.otherConfiguration?.header?.url ?? ''}/>,
										footer: <img className={`w-100 object-fit-fill ${ currentScene?.postType?.otherConfiguration?.footer?.isOverlay ? 'position-absolute top-0' : '' }`} src={currentScene?.postType?.otherConfiguration?.footer?.url ?? ''}/>
									}}
									imgClassName="shadow-sm"
									aspectRatio={1}
								/>            
							</div>          
						</div>
					) : (<></>)
				}

			</div>
		</div>
	)
}

export default Canvas
