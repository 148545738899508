import { useEffect, useState } from "react";
import { Row, Button } from "react-bootstrap";
import ResponsivePagination from 'react-responsive-pagination';
import 'react-responsive-pagination/themes/classic.css';
import FreePosterFilter from "./FreePosterFilter";
import { Plus } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import { getFreePosters, deleteFreePostersById, duplicateFreePosterById,getFreePostersFiltered } from "../../services/freeposters";
import FreePosterCard from "./FreePosterCard/FreePosterCard";
import Swal from "sweetalert2";
import { addProductToCart } from "../../services/cart";
import { useNavigate, Link } from "react-router-dom";
import { getRouteDataFromRouteId, generateDynamicURL } from "../../helpers/helpers";
import { Notyf } from 'notyf';
import 'notyf/notyf.min.css';

const ListFreePoster = () => {
	const [posts, setPosts] = useState([]);
	const [totalItems, setTotalItems] = useState();
	const [isLoading, setIsLoading] = useState(false);
	const { t } = useTranslation();
	const [pageCount, setPageCount] = useState(0);
	const [paginateCurrentPage, setCurrentPagePaginate] = useState(0);
	const [reduceValue, setReduceValue] = useState(0);
	const [filterString, setFilterString] = useState();
	const [changePageLoading, setChangePageLoading] = useState(false);
	const [addToCartLoading, setAddToCartLoading] = useState(false);
	const navigate = useNavigate();

	const postsPerPage = 10;

	const notyf = new Notyf({
		duration: 5000,
			position: {
				x: 'right',
				y: 'top',
				},
			types: [
				{
					type: 'info',
					background: 'green',
					icon: false,
			
				}
			]
		});
	
	const fetchData = async () => {
		setIsLoading(true);
		const response = await getFreePosters();
		setPosts(response.items);
		setTotalItems(response.totalItems);

		setIsLoading(false);
	};

	useEffect(() => {
		fetchData();
		
	}, []);

	useEffect(() => {
		if(paginateCurrentPage>0){
		handlePageClick(paginateCurrentPage);
	}
	}, [paginateCurrentPage]);

	useEffect(() => {
		setPageCount(Math.ceil(totalItems / postsPerPage));
	}, [posts]);

	useEffect(() => {
		if (filterString && filterString.trim() !== '') {
			filterDat();
		}
}, [filterString]);

	useEffect(() => {
		highlightAddedToCartFreePoster(posts);
	},[posts]);

	useEffect(() => {
		highlightAddedToCartFreePoster(posts);
	},[reduceValue]);

	const getFilteredFreePoster = (data) => {
		setPosts(data.items);
		setTotalItems(data.totalItems);
	};

	const queryFilter = (data) => {
		setFilterString(data);
		
	};


	const filterDat = async () => {
		
		let filteredfreePosters = await getFreePostersFiltered(1,filterString);
		setPosts(filteredfreePosters.items);
		setTotalItems(filteredfreePosters.totalItems);
	}

	const handlePageClick = async (data) => {
		// let currentPage = data.selected + 1;
		let currentPage = data;
		setChangePageLoading(true);
		// setCurrentPagePaginate(data.selected);
		setCurrentPagePaginate(data);
		let freePosters = '';
		if(filterString){
			freePosters = await getFreePostersFiltered(currentPage,filterString);
		}else{
			freePosters = await getFreePosters(currentPage);
		}
		setChangePageLoading(false);
		 

		setPosts(freePosters.items);
		setTotalItems(freePosters.totalItems);
		setReduceValue(reduceValue + 1);
		// scroll to the top
		window.scrollTo(0, 0);
	};

	const redirectToEditPage = (freePosterId) => {
		const url = generateDynamicURL(
			getRouteDataFromRouteId("free_poster_editor")?.path,
			{
				mode: "new",
				freePosterId: freePosterId?.toString()
			}
		);
		navigate(url);
	};

	const resetPaginationNumber = (data) => {
		setCurrentPagePaginate(data);
	};

	const redirectToPreviewPage = (poster_id, format, orientation) => {};

	const addItemToCart = async (newItem) => {
		const addToCart = await addProductToCart(newItem,"free_poster");
		return addToCart;
	};

	const addToCart = (freePosterData) => {
		const freePosterID = freePosterData.id;
		const itemData = {
			freePosterId: freePosterID,
			radioValue: 1, //with or without image
			format: freePosterData.formatId,
			orientation: freePosterData.orientation,
			customData: "",
		};

		if (itemData.format === "") {
			Swal.fire("Please Select a format", " ", "error");
			return false;
		} else if (itemData.orientation === "") {
			Swal.fire("Please Select an orientation", " ", "error");
			return false;
		}

		setAddToCartLoading((prevState) => ({
			...prevState,
			[freePosterID]: true,
		}));

		addItemToCart(itemData).then(
			setAddToCartLoading((prevState) => ({
				...prevState,
				[freePosterID]: false
			}))
		);

		let addToCartButton = document.getElementById("product-card-" + freePosterID);
		addToCartButton.style.backgroundColor = "#A6FF96";

		notyf.success(t("cartPage.cartTable.addedToCart"));
		setReduceValue(reduceValue + 1);
	};

		const deleteItem = async (freePosterID) => {
		Swal.fire({
			title: t("freePosters.freePosterSure"),
			text: t("freePosters.freePosterSureText"),
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: t("freePosters.freePosterDeleteConfirm"),
			cancelButtonText: t("freePosters.freePosterDuplicateCancel"),
			showLoaderOnConfirm: true,
			returnFocus: false,
			preConfirm: () => {
				return deleteFreePostersById(freePosterID)
					.then(response => {
						// Handle the response from deleteFreePostersById
						// You can check the response to ensure the deletion was successful
						return response; // This value will be passed to the next then() block
					})
					.catch(error => {
						Swal.showValidationMessage(
							`Request failed: ${error}` // Show error message within the Swal
						);
					});
			}
		}).then((result) => {
			if (result.isConfirmed && result.value) {
				// The promise from preConfirm resolved successfully and the Swal was confirmed
				Swal.fire(t("freePosters.deleted"), t("freePosters.freePosterDeleted"), "success");

				// Remove the item from the DOM
				const itemElement = document.getElementById(`product-card-${freePosterID}`);
				if (itemElement) {
					itemElement.remove();
				}

				// Optionally, trigger fetching data again if needed
				fetchData();
			}
		});
	};

	const duplicateFreePoster = (freePosterID) => {
		Swal.fire({
			title: t("freePosters.freePosterSure"),
			text: t("freePosters.freePosterSureText"),
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: t("freePosters.freePosterDuplicateConfirm"),
			cancelButtonText: t("freePosters.freePosterDuplicateCancel"),
			showLoaderOnConfirm: true,
			returnFocus: false,
			preConfirm: async () => {
				try {
					const duplicate = await duplicateFreePosterById(freePosterID);
					// Handle the successful duplication response if needed
					return duplicate; // This will be passed to the next then() block as result.value
				} catch (error) {
					Swal.showValidationMessage(`Request failed: ${error}`);
					// Throwing error here will prevent the success Swal from showing
					throw new Error(error);
				}
			}
		}).then((result) => {
			if (result.isConfirmed && result.value) {
				// Here, result.value will contain the response from duplicateFreePosterById if the AJAX call was successful
				Swal.fire(t("freePosters.duplicated"), t("freePosters.freePosterDuplicated"), "success");
				fetchData(); // Assuming fetchData() updates the UI with the new data
			}
		});
	};

	const highlightAddedToCartFreePoster = (posts) => {
		const cartItems = JSON.parse(localStorage.getItem('updatedCartItems'));
		
		let productIdMatches = false;

		if (Array.isArray(cartItems)) {      
			posts?.forEach((item) => {
				const productCartData = cartItems.find((cartItem) => cartItem.freePosterId === item.id);
				const cardElement = document.getElementById(`product-card-${item.id}`);

				if(productCartData){
					if (productCartData.type === 'free_poster'){
						if(parseInt(productCartData.freePosterId) === parseInt(item.id)){
							productIdMatches = true;
						};

						if (cardElement && productIdMatches) {
							cardElement.style.backgroundColor = "#A6FF96";
						}else{
							cardElement.style.backgroundColor = "white";
						}
					}
				}
			})
		}
	}

	return (
		<div className="nw-main-free-poster-list-container poster-list-container px-5 py-5">
			<div className="d-flex justify-content-between h-100 mb-5">
				<div className="w-75">
					<FreePosterFilter getFilteredFreePoster={getFilteredFreePoster} queryFilter={queryFilter} resetPaginationNumber = {resetPaginationNumber}/>
				</div>
				<Link 
					to={generateDynamicURL(
						getRouteDataFromRouteId("free_poster_editor")?.path,
						{
							mode: "new",
							freePosterId: "0"
						}
					)}
				>
					<Button type="button" variant="success" className="new-poster-btn text-nowrap h-100 px-3 rounded-1">
						<Plus size={25}/>
						<span className="new-poster-button">
							{ t('postersPage.topButtonLabel') }
						</span>
					</Button>
				</Link>
			</div>
			<div className="mb-3">
				{changePageLoading ? (
					<div id="backdrop">
						<div className="text-center loading">
							<div className="spinner-border nw-spinner" role="status"></div>
						</div>
					</div>
				) : null}
				<div className="d-flex flex-column justify-content-between" style={{
					minHeight: '70svh'
				}}>
					{isLoading ? (
							<p> {t("loading")}</p>
					) : (
						<>
							<Row xs={1} sm={2} md={2} lg={3} xl={4} xxl={5} className="gy-4">
								{posts?.length === 0 ? (
										<p>{t("no_Posts_Available")}</p>
								) : (
									posts?.map((post) => (
										<FreePosterCard 
											data={post} 
											addToCartLoading={addToCartLoading} 
											redirectToEditPage={redirectToEditPage} 
											redirectToPreviewPage={redirectToPreviewPage} 
											addToCart={addToCart} 
											deleteItem={deleteItem} 
											duplicateFreePoster={duplicateFreePoster}
										/>
									))
								)}
							</Row>
							<div className="w-100 my-4">
								<ResponsivePagination
									previousLabel={t("back")} 
									nextLabel={t("next")} 
									current={paginateCurrentPage}
									total={pageCount}
									onPageChange={setCurrentPagePaginate}
								/>
							</div>
						</>
					)}
				</div>
			</div>
		</div>
	);
};
export default ListFreePoster;
