import { getBackendApiUrl, getEasyPlvApiToken, getBackendUrl, getAuthTokenWithRefresh } from "../config";
import { checkApiResponse } from "./auth";

// const getEasyPlvApiToken() = getEasyPlvApiToken();

export const filterOperation = async (filter, itemPerPage = 10, locale="fr") => {
	const authToken = await getAuthTokenWithRefresh();
	const res = await fetch(getBackendApiUrl(`operation/byUser?${filter}&itemsPerPage=${itemPerPage}`), {
		method: "GET",
		headers: {
			Authorization: `Bearer ${authToken}`,
			"X-EasyPLV-Api-Token": getEasyPlvApiToken(),
			'nw-front-locale': locale ?? 'fr',
		},
	});
	checkApiResponse(res);
	const data = await res.json();
	return data;
};

export const fetchOperations = async (currentPage, itemPerPage = 10, locale="fr") => {
	const authToken = await getAuthTokenWithRefresh();
	const res = await fetch(
		getBackendApiUrl(
			`operation/byUser?page=${currentPage}&itemsPerPage=${itemPerPage}`
		),
		{
			method: "GET",
			headers: {
				Authorization: `Bearer ${authToken}`,
				"X-EasyPLV-Api-Token": getEasyPlvApiToken(),
				'nw-front-locale': locale ?? 'fr'
			},
		}
	);
	checkApiResponse(res);
	const data = await res.json();
	return data;
};

export const formatDateRange = (startDate, endDate) => {
	// Define your options for day, month, and year
	const dayOptions = { day: 'numeric' };
	const monthOptions = { month: 'long' };
	const yearOptions = { year: 'numeric' };

	// Create date objects from the input
	const startDateObj = new Date(startDate);
	const endDateObj = new Date(endDate);

	// Extract the day, month, and year from the start date
	const startDay = startDateObj.toLocaleDateString(undefined, dayOptions);
	const startMonth = startDateObj.toLocaleDateString(undefined, monthOptions);
	const startYear = startDateObj.toLocaleDateString(undefined, yearOptions);

	// Extract the day, month, and year from the end date
	const endDay = endDateObj.toLocaleDateString(undefined, dayOptions);
	const endMonth = endDateObj.toLocaleDateString(undefined, monthOptions);
	const endYear = endDateObj.toLocaleDateString(undefined, yearOptions);

	// Compare the months and construct the string accordingly
	if (startDateObj.getMonth() === endDateObj.getMonth() && startDateObj.getFullYear() === endDateObj.getFullYear()) {
		// Same month and year
		return `du ${startDay} au ${endDay} ${startMonth} ${startYear}`;
	} else {
		// Different month or year
		return `du ${startDay} ${startMonth} au ${endDay} ${endMonth} ${endYear}`;
	}
};


export const highlightProductCards = (posts,withImageChecked=0,selectedFormat=0,selectedOrientationValue = "") => {

	try {
		const cartItems = JSON.parse(localStorage.getItem('updatedCartItems'));
		const allFormats = JSON.parse(localStorage.getItem('formatSelectData'));
		let cartFormat ="";
		let cartOrientation = "";
		let cartFormatObj = 0;

		
		if (Array.isArray(cartItems)) {
			posts?.forEach((item) => {
				const productCartData = cartItems.find((cartItem) => cartItem.productId === item.id);
				
				const cardElement = document.getElementById(`product-card-${item.id}`);

				if (cardElement !== null) {
						let productIdMatches = false;
						let formatMatches = false;
						let orientationMatches = false;
						let withImageMatches = false;
						let orientationValue = null;
						
						const isImageChecked = withImageChecked ? 1 : 0;
						const formatValue = parseInt(selectedFormat);
						
						orientationValue = selectedOrientationValue;    
						
						if(productCartData){

							cartFormat = productCartData.format;
							cartFormatObj = allFormats.find(item => item.value === cartFormat);
					
							cartOrientation = productCartData.orientation;

							if(parseInt(productCartData.productId) === parseInt(item.id)){
								productIdMatches = true;
							};
							if(productCartData.format === formatValue)
							{
								formatMatches = true;
							}
						
							if(productCartData.orientation === orientationValue)
							{
								orientationMatches = true;
							}
							if(productCartData.with_image === (isImageChecked === 1)) {
									withImageMatches = true;
							}      

							if (cardElement && productIdMatches && withImageMatches && formatMatches && orientationMatches) {
								cardElement.style.backgroundColor = "#A6FF96";
							}else if(cardElement && productIdMatches && !withImageMatches && !formatMatches && !orientationMatches){
								cardElement.style.backgroundColor = "#A6FF96";
							}else if(cardElement && productIdMatches && withImageMatches && !formatMatches && !orientationMatches){
								cardElement.style.backgroundColor = "#A6FF96";
							}else{
								cardElement.style.backgroundColor = "white";
							}
							
						}
				}
			});
		}
		return { format: cartFormatObj, orientation: cartOrientation };
	} catch (error) {
			// console.error(error);
	}
}

/**
 * Determines the state data based on the state obtained
 * 
 * @param {string} frontState - The state obtained from the Operation item data
 * @param {Function|null|undefined} t - Translation function, if ever needed
 * @returns {StateDataByFrontState}
 */
export const getStateDataByFrontState = (frontState, t = null) => {
	let stateColor = "primary";
	let textColor = "";
	let isDisabled = true;
	let translationKey = `operationsPage.front_state.${frontState}`;
	let stateOrder = 0;

	switch (frontState) {
		case "coming_soon":
			stateColor = "primary";
			break;
		case "in_progress":
			stateColor = "warning"
			break;
		case "published":
			stateColor = "success";
			isDisabled = false;
			break;
		default:
			stateColor = "primary";
			break;
	}

	return {
		stateColor: stateColor,
		textColor: textColor,
		isDisabled: isDisabled,
		translationKey: translationKey,
		stateOrder: stateOrder
	};
}


export const splitWordsWithKeys = (text, t) => {
	const words = text.match(/(\b\w+\b)/g);
	let finalTranslatedDate = "";
	 if (!words) {
	  return [];
	}
	// Add a key to each word in the array
	const wordsWithKeys = words.map((word, index) => ({
	  key: index,
	  value: word,
	}));
	
	if(wordsWithKeys){
		if(wordsWithKeys.length == 7){
		// Different month or year	
			const fromText = t(`months.${wordsWithKeys[0]?.value}`)
			const toText = t(`months.${wordsWithKeys[3]?.value}`)
			const monthsFrom = t(`months.${wordsWithKeys[2]?.value}`)
			const monthsTo = t(`months.${wordsWithKeys[5]?.value}`)
			finalTranslatedDate = fromText +" "+wordsWithKeys[1]?.value +" "+monthsFrom+" "+toText+" "+wordsWithKeys[4]?.value + " "+monthsTo + " "+ wordsWithKeys[6]?.value;

		}else if(wordsWithKeys.length == 6){
		// Same month and year
			const fromText = t(`months.${wordsWithKeys[0]?.value}`)
			const toText = t(`months.${wordsWithKeys[2]?.value}`)
			const monthsFrom = t(`months.${wordsWithKeys[1]?.value}`)
			const monthsTo = t(`months.${wordsWithKeys[4]?.value}`)
			finalTranslatedDate = fromText + " "+wordsWithKeys[1]?.value+ " "+ toText+" "+wordsWithKeys[3]?.value + " "+monthsTo + " "+ wordsWithKeys[5]?.value;
		}
		
	}

	return finalTranslatedDate;
  }




	