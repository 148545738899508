import React, { useState } from 'react';
import Select from "react-select";
import makeAnimated from 'react-select/animated';
import { useLayoutContext } from '../../../../_layouts/MainLayout';
import './NwCustomSelect.css';

/**
 * A custom select component with additional props.
 * 
 * @param {NwCustomSelectProps} props - The props for the NwCustomSelect component.
 * @returns {JSX.Element} The custom select component.
 */
const NwCustomSelect = ({
		secondary = false,
		sm = false,
		uiOverrides = {},
		hideDropdown = false,
		menuPortalTarget = document.body,
		...additionalProps
	}) => {

	const [ isMenuOpen, setIsMenuOpen ] = useState(false);
	if (uiOverrides?.debug) {
		console.log(uiOverrides);
	}
	const { currentPlatform } = useLayoutContext();
	const animatedComponents = makeAnimated();
	const nwCustomStyles = {
		control: (provided, state) => ({
			...provided,
			background: `linear-gradient(to right, ${uiOverrides?.background?.control ?? 'white'} 97%, transparent 0)`,
			border: 'none',
			width: uiOverrides?.width?.control ?? '',
			borderBottom: isMenuOpen ? uiOverrides?.border?.control?.borderBottom?.onMenuOpen ?? '' : uiOverrides?.border?.control?.borderBottom?.onMenuClose ?? (secondary ? `2px solid var(--nw-secondary-color)` : `0px solid ${state.isDisabled ? 'var(--nw-accent-color-disabled-1)' : 'var(--nw-accent-color-1)'}`),
			borderRadius: isMenuOpen ? uiOverrides?.border?.control?.borderRadius?.onMenuOpen ?? '.3rem .3rem 0rem 0rem' : uiOverrides?.border?.control?.borderRadius?.onMenuClose ?? '.3rem',
			overflow: 'hidden',
			boxShadow: 'none',
			cursor: 'pointer',
			minHeight: secondary ? '' : '2.8rem',
			fontSize: 'var(--nw-responsive-font-size-md)',
			'&:hover': {
				borderBottom: uiOverrides?.border?.control?.hover?.borderBottom ?? (secondary ? '' : ''),
			}
		}),
		placeholder: (provided, state) => ({
			...provided,
			padding: `0 0 0 ${uiOverrides?.displaySpacing?.value ?? '.2rem'}`,
			color: '#807E7E'
		}),
		indicatorsContainer: (provided, state) => ({
			...provided,
			// width: uiOverrides?.width?.dropdown ?? (sm ? '40%' : '25%'),
			justifyContent: 'flex-end',
			position: 'relative',
			width: 'fit-content'
		}),
		clearIndicator: (provided, state) => ({
			...provided,
			padding: '.5em',
			position: 'absolute',
			left: 0,
			transform: 'translateX(-100%)',
			zIndex: 9
		}),
		dropdownIndicator: (provided, state) => ({
			...provided,
			backgroundColor: state.isDisabled ? 'var(--nw-accent-color-disabled-1)' : (secondary ? '#cccccc' : 'var(--nw-accent-color-1)'),
			color: 'white',
			padding: '.5em',
			height: '100%',
			width: uiOverrides?.width?.dropdownButton ?? (sm ? '4rem' : '3rem'),
			// aspectRatio: sm ? '' : '1.2',
			alignItems: 'center',
			justifyContent: 'center',
			transition: 'all 100ms ease-in-out',
			':hover': {
				backgroundColor: secondary ? '#888' :'var(--nw-accent-color-hover-1)',
				color: 'white'
			},
			'svg': {
				transition: 'all 100ms ease-in-out',
				rotate: isMenuOpen ? '180deg' : '',
			}
		}),
		indicatorSeparator: () => ({
			display: 'none'
		}),
		valueContainer: (provided, state) => ({
			...provided,
			width: uiOverrides?.width?.valueContainer ?? (sm ? '60%' : '75%'),
			height: uiOverrides?.height?.valueContainer ?? '',
			padding: '0',
			margin: `0 0 0 ${uiOverrides?.displaySpacing?.valueContainer ?? '1rem'}`,
			textOverflow: 'clip',
			overflow: 'hidden',
			borderLeft: uiOverrides?.border?.valueContainer?.borderLeft ??  `2px solid
						${state.isDisabled ? `var(${secondary ? '--nw-primary-color-disabled' : '--nw-accent-color-disabled-1'})` 
						: `var(${secondary ? '--nw-primary-color' : '--nw-accent-color-1'})`}`,
		}),
		input: (provided, state) => ({
			...provided,
			padding: `0 0 0 ${uiOverrides?.displaySpacing?.value ?? '.3rem'}`,
			margin: '0'
		}),
		singleValue: (provided, state) => ({
			...provided,
			color: '#000',
			marginLeft: '0',
			marginRight: '0',
			padding: `0 0 0 ${uiOverrides?.displaySpacing?.value ?? '.3rem'}`,
		}),
		menu: (provided, state) => ({
			...provided,
			animation: `nwCustomSelectFadeInAnimation${additionalProps.menuPlacement === 'top' ? 'Reverse' : ''} 200ms ease-in-out forwards`,
			marginTop: '-2%',
			borderRadius: '0rem 0rem .3rem .3rem',
			overflow: 'hidden',
			borderColor: '#DDDDDD',
			zIndex: 99999
		}),
		menuPortal: base => ({
            ...base,
            zIndex: 99999 // Adjust this zIndex value as needed
        }),
		option: (provided, state) => ({
			...provided,
			...uiOverrides?.option,
			fontSize: "var(--nw-responsive-font-size)",
			backgroundColor: 'transparent'
		}),
		menu: (provided, state) => ({
			...provided,
			...uiOverrides?.menu
		}),
		menuList: (provided, state) => ({
			...provided,
			...uiOverrides?.menuList
		}),
		// Add any other components you want to customize...
	}

	const nwCustomClassnames = {
		dropdownIndicator: (state) => `nw-custom-select-dropdown-indicator ${hideDropdown ? 'd-none' : ''} ${ isMenuOpen ? `${secondary ? 'is-secondary' : ''} is-focused` : ''  }`,
		menu: (state) => 'shadow mb-0',
		menuList: (state) => 'py-0',
		option: (state) => state.isFocused ? (secondary ? 'nw-custom-select-option-secondary' : 'nw-bg-accent-1 text-white') : 'text-black',
		...additionalProps.classNames
	}

	return (
		<>
			<Select
				{ ...additionalProps }
				styles={nwCustomStyles}
				closeMenuOnSelect={true}
				classNames={nwCustomClassnames}
				components={animatedComponents}
				menuPortalTarget={menuPortalTarget}
				onMenuOpen={() => { setIsMenuOpen(true) }}
				onMenuClose={() => { setIsMenuOpen(false) }}
			/>
		</>
	);
}

/* The `NwCustomSelect.defaultProps` is setting default values for the `uiOverrides` prop of the
`NwCustomSelect` component. This means that if the `uiOverrides` prop is not provided when using the
`NwCustomSelect` component, it will use these default values instead. The default values include
`debug`, `name`, `width`, and `displaySpacing` properties, each with their own default values. */
NwCustomSelect.defaultProps = {
    uiOverrides: {
		debug: null,
		name: null,
        width: {
			control: null,
			valueContainer: null, 
			dropdown: null, 
			dopdownButton: null 
		},
		border: {
			valueContainer: null, // all css borders
			control: null // all css borders
		},
		height: {
			valueContainer: null
		},
		background: {
			control: null
		},
		option: {},
        displaySpacing: {
			value: null,
			valueContainer: null
		},
    },
};

export default NwCustomSelect;