import React, { useEffect, useState } from "react";
import ModelSelection from '../components/ModelSelection';
import { NwCanvasElementClassNames, NwCanvasElementStyles } from "../components/Canvas/Canvas";


export const EditorUseContext = React.createContext<any>({});

export const EditorUseProvider = ({usage, children} : any) => {
    const [use, setUse] = useState();
    const [editorContents, setEditorContents] = useState(
        {
            'model_selection' : ModelSelection,
            'canvas' : () => {return <></>}
        }
    )
    const [currentEditorContent, setCurrentEditorContent] = useState({
        "hideCanvas" : false, 
        "content": "canvas"
    });

    const [ canvasClassNames, setCanvasClassNames ] = useState<NwCanvasElementClassNames | undefined>(undefined)
    const [ canvasStyles, setCanvasStyles ] = useState<NwCanvasElementStyles | undefined>(undefined)

    useEffect(() => {
            setUse(usage);
    }, [setUse, usage]);

    const context = {
        use,
        setUse,
        editorContents,
        setEditorContents,
        currentEditorContent,
        setCurrentEditorContent,
        canvasClassNames,
        setCanvasClassNames,
        canvasStyles,
        setCanvasStyles
    }

    return <EditorUseContext.Provider value={context}>{children}</EditorUseContext.Provider>
}