import { Fragment, useEffect, useState } from "react";
import { getLegalMentions } from "../../../../services/posters";
import LegalMentionsItem from "./LegalMentionsItem";
import { NwLoaderSpinner } from "../../../_utilityComponents/NwIcons/NwIcons";
import './LegalMentions.css';

const LegalMentions = (props) => {
	const { productId, getLegalMention } = props;

	const [posts, setPosts] = useState([]);
	const [totalItems, setTotalItems] = useState(0);
	const [selectedItems, setSelectedItems] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	const updateSelectedLegalMentions = props.updateSelectedLegalMentions;
	const selectedLegalMentions = props.selectedLegalMentions;
	const templateExtensionLegalMentions = props.templateExtensionLegalMentions;
	const discountLegalMentions = props.discountLegalMentions;
	const isDisabled = props?.isDisabled || false;

	const upperLegalMentionsWithDuplicates = [...templateExtensionLegalMentions].concat([...discountLegalMentions]);
	const uniqueUpperLegalMentions = {};
	upperLegalMentionsWithDuplicates.forEach(upperLegalNotice => {
		const upperLegalNoticeId = upperLegalNotice.id;
		if (upperLegalNoticeId in uniqueUpperLegalMentions) {
			if (uniqueUpperLegalMentions[upperLegalNoticeId].source === "template_extension") {
				delete  uniqueUpperLegalMentions[upperLegalNoticeId].templateExtensionId
			}
			uniqueUpperLegalMentions[upperLegalNoticeId].source = "discount";
			uniqueUpperLegalMentions[upperLegalNoticeId].discountId = upperLegalNotice.discountId;
		} else {
			uniqueUpperLegalMentions[upperLegalNoticeId] = upperLegalNotice;
		}
	})
	const upperLegalMentions = Object.values(uniqueUpperLegalMentions);
	const fetchData = async (productId) => {
		setIsLoading(true);
		const res = await getLegalMentions(productId);
		const offsetPosition = templateExtensionLegalMentions.length;
		const items = res.items.map((post, idx) => {return {...post, position: offsetPosition + idx} ;})
		setPosts(posts.concat(items));
		setTotalItems(res.totalItems);
		setIsLoading(false);
	};

	useEffect(() => {
		fetchData(productId);
	}, []);

	useEffect(() => {
		setSelectedItems(selectedLegalMentions);
	}, [selectedLegalMentions]);

	const handleFormLegalMentionItemClick = (item) => {
		item.source = 'form';
		let newSelectedItems = {...selectedItems};
		let newSelectedLegalsItems = newSelectedItems?.legals ? newSelectedItems?.legals : [];
		const newSelectedLegalsItemsIds = newSelectedLegalsItems.map(newSelectedLegalItem => newSelectedLegalItem.id);
		const index = newSelectedLegalsItemsIds.indexOf(item.id);

		if (index > -1) {
			newSelectedLegalsItems = [...newSelectedLegalsItems.slice(0, index), ...newSelectedLegalsItems.slice(index + 1)];
		} else {
			newSelectedLegalsItems = [...newSelectedLegalsItems, item];
		}
		newSelectedItems['legals'] = newSelectedLegalsItems;
		setSelectedItems(newSelectedItems);
		updateSelectedLegalMentions(newSelectedItems);
	};

	// TO_DO_NW @priyesh refactor into a single function
	const handleTemplateExtensionLegalItemClick = (item) => {
		item.source = 'template_extension';
		let newSelectedItems = {...selectedItems};
		let newSelectedLegalsItems = newSelectedItems?.legals ? newSelectedItems?.legals : [];
		const newSelectedLegalsItemsIds = newSelectedLegalsItems.map(newSelectedTemplateExtensionItem => newSelectedTemplateExtensionItem.id);
		const index = newSelectedLegalsItemsIds.indexOf(item.id);

		if (index > -1) {
			newSelectedLegalsItems.splice(index, 1)
		} else {
			newSelectedLegalsItems =  [...newSelectedLegalsItems, item];
		}

		newSelectedItems['legals'] = newSelectedLegalsItems;
		setSelectedItems(newSelectedItems);
		updateSelectedLegalMentions(newSelectedItems)
	}

	// TO_DO_NW @priyesh refactor into a single function
	const handleDiscountLegalItemClick = (item) => {
		item.source = 'discount';
		let newSelectedItems = {...selectedItems};
		let newSelectedLegalsItems = newSelectedItems?.legals ? newSelectedItems?.legals : [];
		const newSelectedLegalsItemsIds = newSelectedLegalsItems.map(newSelectedDiscountItem => newSelectedDiscountItem.id);
		const index = newSelectedLegalsItemsIds.indexOf(item.id);

		if (index > -1) {
			newSelectedLegalsItems.splice(index, 1)
		} else {
			newSelectedLegalsItems =  [...newSelectedLegalsItems, item];
		}

		newSelectedItems['legals'] = newSelectedLegalsItems;
		setSelectedItems(newSelectedItems);
		updateSelectedLegalMentions(newSelectedItems)
	}

	return (
		<>
			<div className={`h-auto legal-mentions-main-container ${isDisabled ? 'is-nw-disabled bg-secondary' : 'bg-white'}`}>
				{
					upperLegalMentions && 
					upperLegalMentions.map((upperLegal, idx) => {

						const selectedItemIds = selectedItems?.legals?.map(selectedItem => selectedItem.id);
						upperLegal.position = selectedItemIds?.includes(upperLegal.id) ? selectedItems?.legals[selectedItemIds.indexOf(upperLegal.id)].position : upperLegal.position;
						let clickHandler = () => {};
						if (upperLegal.source === "discount") clickHandler = handleDiscountLegalItemClick;
						else if (upperLegal.source === "template_extension") clickHandler = handleTemplateExtensionLegalItemClick;
						return (
							<LegalMentionsItem
								isDisabled={isDisabled}
								key={idx}
								item={upperLegal} 
								onClick={clickHandler}
								isSelected={selectedItems?.legals?.map(selectedItem => selectedItem?.id).includes(upperLegal?.id)}
							/>
						)
					})
				}		
			</div>
			<div className={`legal-mentions-main-container ${isDisabled ? 'is-nw-disabled bg-secondary' : 'bg-white'}`}>
				{posts?.length > 0 ? (
					posts?.map((item, idx) => {
						const upperLegalMentionIds = upperLegalMentions.map(upperLegalMention => upperLegalMention.id);
						if (!(upperLegalMentionIds.includes(item.id))) {
							const selectedItemIds = selectedItems?.legals?.map(selectedItem => selectedItem.id);
							item.position = selectedItemIds?.includes(item.id) ? selectedItems?.legals[selectedItemIds.indexOf(item.id)].position : item.position;
							return (
								<LegalMentionsItem 
									key={idx} 
									item={item}
									isDisabled = {isDisabled} 
									onClick={handleFormLegalMentionItemClick}
									isSelected={selectedItems?.legals?.map(selectedItem => selectedItem?.id).includes(item?.id)}
								/>
							)	
						} else {
							return <></>
						}
						
					})
				) : (
					<div className="h-100 d-flex justify-content-center align-items-center">
						{isLoading ? (
							<NwLoaderSpinner isSpinner size={40}/>
						) : (
							"No items to display"
						)}
					</div>
				)}
			</div>
		</>
	);
};

export default LegalMentions;
