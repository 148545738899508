import PanelItem from "./PanelItem"
import PanelsList from "./PanelsList"

const Panels = () => {
  return (
    <>
      <PanelsList />
      <PanelItem />
    </>
  )
}

export default Panels
