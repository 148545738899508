/**
 * Enum for platform types based on screen size.
 * These correspond to the media query keys in `nwMediaQueries`.
 * 
 * @readonly
 * @enum {string}
 */
export const PlatformTypes = {
    IS_MOBILE: 'is-mobile',
    IS_TABLET: 'is-tablet',
    IS_SMALL_DESKTOP: 'is-small-desktop',
    IS_MEDIUM_DESKTOP: 'is-medium-desktop',
    IS_LARGE_DESKTOP: 'is-large-desktop'
};

/**
 * An object containing CSS media query strings for different screen sizes.
 * The object is frozen using `Object.freeze()` to prevent modifications to its properties.
 * 
 * @constant {Object<string, string>}
 * @property {string} is-mobile - Media query for mobile devices (max-width: 768px).
 * @property {string} is-tablet - Media query for tablet devices (min-width: 768px and max-width: 1024px).
 * @property {string} is-small-desktop - Media query for small desktop devices (min-width: 1025px and max-width: 1280px).
 * @property {string} is-medium-desktop - Media query for medium desktop devices (min-width: 1281px and max-width: 1440px).
 * @property {string} is-large-desktop - Media query for large desktop devices (min-width: 1441px and max-width: 1680px).
 */
export const nwMediaQueries = Object.freeze({
    'is-mobile': '(max-width: 768px)',
    'is-tablet': '(min-width: 768px) and (max-width: 1024px)',
    'is-small-desktop': '(min-width: 1025px) and (max-width: 1280px)',
    'is-medium-desktop': '(min-width: 1281px) and (max-width: 1440px)',
    'is-large-desktop': '(min-width: 1441px) and (max-width: 1680px)'
});

export const moduleTypes = Object.freeze({
    plv: 'plv',
    social_media: 'social_media'
})

export const SUBCRIPTION_MODE = Object.freeze({
    CLOUD: 'cloud',
    LOCAL_SERVER: 'local_server'
})