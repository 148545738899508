import {useState, useEffect, useRef} from "react";
import Chart from "chart.js/auto";
import {useTranslation} from "react-i18next";
import {getDashboardStats} from "../../services/dashboard";
import NwCustomDropdown from "../../components/_utilityComponents/NwFormsUi/CustomDropdown/NwCustomDropdown";
import {NwLoaderSpinner} from "../../components/_utilityComponents/NwIcons/NwIcons";

const DashboardChart = () => {
    const {i18n, t} = useTranslation();
    const chartRef = useRef(null);

    const [posts, setPosts] = useState([]);
    const [isChartloading, setIsChartLoading] = useState(false);
    const [dateLablels, setDateLabels] = useState([]);
    const [chartData, setChartData] = useState([]);
    const [chartZipData, setChartZipData] = useState([]);
    const [chartPdfData, setChartPdfData] = useState([]);
    const [chartPrintData, setChartPrintData] = useState([]);
    const [maxValue, setMaxValue] = useState([100]);


    const fetchData = async (type, sort, locale) => {
        setIsChartLoading(true);
        try {
            const res = await getDashboardStats(type, sort, locale);
            // console.log('Fetched data:', res);
            setPosts(res);
            if (res?.[type]) {
                switch (type) {
                    case 'all_print':
                        setDateLabels(res[type].dateOnly);
                        setChartData(res[type].statsOnly);
                        setMaxValue(res[type].maxValue);
                        break;
                    case 'zip':
                        setDateLabels(res[type].dateOnly);
                        setChartZipData(res[type].statsOnly);

                        break;
                    case 'pdf':
                        setDateLabels(res[type].dateOnly);
                        setChartPdfData(res[type].statsOnly);

                        break;
                    case 'print':
                        setDateLabels(res[type].dateOnly);
                        setChartPrintData(res[type].statsOnly);

                        break;
                }
            } else {
                setIsChartLoading(false);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setIsChartLoading(false);
        }
    };


    useEffect(() => {

        fetchData('all_print', 'month', i18n.language);
        fetchData('zip', 'month', i18n.language);
        fetchData('pdf', 'month', i18n.language);
        fetchData('print', 'month', i18n.language);

    }, []);

    const handleThisMonthClick = () => {
        fetchData('all_print', 'month', i18n.language);
        fetchData('zip', 'month', i18n.language);
        fetchData('pdf', 'month', i18n.language);
        fetchData('print', 'month', i18n.language);

    };

    const handleThisYearClick = () => {
        fetchData('all_print', 'year', i18n.language);
        fetchData('zip', 'year', i18n.language);
        fetchData('pdf', 'year', i18n.language);
        fetchData('print', 'year', i18n.language);

    };


    useEffect(() => {
        const ctx = chartRef.current.getContext("2d");

        const myLineChart = new Chart(ctx, {
            type: "line",
            data: {
                labels: dateLablels,
                datasets: [
                    {
                        label: "Imprimés",
                        backgroundColor: "rgba(255,99,132,0.4)",
                        borderColor: "rgba(255,99,132,1)",
                        data: chartPrintData,
                        tension: 0.4,
                        cubicInterpolationMode: "monotone",
                    },
                    {
                        label: "ZIP",
                        backgroundColor: "rgba(54,162,235,0.4)",
                        borderColor: "rgba(54,162,235,1)",
                        data: chartZipData,
                        tension: 0.4,
                        cubicInterpolationMode: "monotone",
                    },
                    {
                        label: "PDF",
                        backgroundColor: "rgba(255,206,86,0.4)",
                        borderColor: "rgba(255,206,86,1)",
                        data: chartPdfData,
                        tension: 0.4,
                        cubicInterpolationMode: "monotone",
                    },
                    {
                        label: "Total Affiches",
                        backgroundColor: "rgba(75,192,192,0.4)",
                        borderColor: "rgba(75,192,192,1)",
                        borderWidth: 1,
                        hoverBackgroundColor: "rgba(75,192,192,0.4)",
                        hoverBorderColor: "rgba(75,192,192,1)",
                        data: chartData,
                        cubicInterpolationMode: "monotone", // Enable smooth lines
                        tension: 0.4,
                    }
                ],
            },
            options: {
                scales: {
                    y: {
                        suggestedMin: 0,
                        suggestedMax: maxValue,
                    },

                },
                plugins: {
                    legend: {
                        position: "bottom", // Set legend position to bottom
                    },
                },
            },

        });

        // Cleanup function to destroy the chart instance when the component unmounts
        return () => {
            myLineChart.destroy();
        };
    }, [dateLablels, chartData, maxValue]);

    return (
        <div
            className="rounded-4 bg-white shadow-lg h-100 rounded-3 py-3"
            style={{
                paddingInline: "var(--nw-responsive-padding-lg)",
            }}
        >
            <div
                className="mb-xxl-2"
                style={{
                    display: "flex",
                    alignItems: "center",
                    height: "fit-content",
                    justifyContent: "space-between",
                }}
            >
        <span className="nw-responsive-font-size ps-2 fw-bold">
			{t("dashboard.chartTitle")}
            {isChartloading ? (
                <NwLoaderSpinner isSpinner size={'1em'} className="ms-1 nw-responsive-font-size"/>) : null}
		</span>
                <NwCustomDropdown
                    dropdownToggleProps={{
                        className: 'fw-bold',
                        iconClassName: 'text-primary'
                    }}
                    dropdownItems={[
                        {
                            eventKey: 'month',
                            displayText: t("dashboard.filterItems.thisMonth"),
                            onClick: handleThisMonthClick
                        },
                        {
                            eventKey: 'year',
                            displayText: t("dashboard.filterItems.thisYear"),
                            onClick: handleThisYearClick
                        },
                    ]}
                >
                    <span className="nw-responsive-font-size text-primary">{t("dashboard.filtre")}</span>
                </NwCustomDropdown>
            </div>
            <canvas className="w-100" ref={chartRef}></canvas>
        </div>
    );
};

export default DashboardChart;
