function LetterCase({ size }: { size: number }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height={size} viewBox="0 0 24 24">
      <path
        d="M20.3337 9.53125V10.3862C19.6167 9.83474 18.7382 9.53431 17.8337 9.53125C16.7286 9.53125 15.6688 9.97024 14.8874 10.7516C14.106 11.533 13.667 12.5928 13.667 13.6979C13.667 14.803 14.106 15.8628 14.8874 16.6442C15.6688 17.4256 16.7286 17.8646 17.8337 17.8646C18.7382 17.8615 19.6167 17.5611 20.3337 17.0096V17.8646H22.0003V9.53125H20.3337ZM17.8337 16.1979C17.3392 16.1979 16.8559 16.0513 16.4447 15.7766C16.0336 15.5019 15.7132 15.1114 15.524 14.6546C15.3347 14.1978 15.2852 13.6951 15.3817 13.2102C15.4782 12.7252 15.7163 12.2798 16.0659 11.9301C16.4155 11.5805 16.861 11.3424 17.3459 11.246C17.8309 11.1495 18.3336 11.199 18.7904 11.3882C19.2472 11.5774 19.6376 11.8979 19.9123 12.309C20.187 12.7201 20.3337 13.2035 20.3337 13.6979C20.3337 14.361 20.0703 14.9968 19.6014 15.4657C19.1326 15.9345 18.4967 16.1979 17.8337 16.1979Z"
        fill="currentColor"
      />
      <path
        d="M12 17.8633H13.8633L7.93167 6L2 17.8633H3.86333L5.53 14.53H10.3333L12 17.8633ZM6.36333 12.8633L7.93167 9.72667L9.5 12.8633H6.36333Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default LetterCase
