import Toast from 'react-bootstrap/Toast';
import { NotificationPrintContext } from '../../../../contexts/NotificationPrintContext';
import { useContext, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { printPdfUsingPrinter } from '../../../../services/print';
import { seeNotification } from '../../../../services/notification';


function PdfPrintGenerationSuccess({notification, handleCloseNotification}) {
    const { t } = useTranslation();
    const body = notification.body  ? notification.body : "";
    const otherData = notification.otherDataJson ? notification.otherDataJson : undefined
    const subject = notification.subject ? notification.subject : "";
    const pdfFileInfo = otherData.pdfFileInfo ? otherData.pdfFileInfo : {};
    const pdfFileName = pdfFileInfo.filename ? pdfFileInfo.filename : undefined;
    const notificationPrintContext = useContext(NotificationPrintContext);
    const openPrintConfirmationWithPrintData = notificationPrintContext.openPrintConfirmationWithPrintData;

    const triggerOpenPrintConfirmation = (otherData) => {
        handleCloseNotification(notification.id);
        openPrintConfirmationWithPrintData(otherData);
    }


    const downloadPdfHandler = () => {
        handleCloseNotification(notification.id);
        if (otherData === undefined) { 
            console.log("Missing data to download pdf");
            return;
        }
        window.open(otherData.pdfFileInfo.cdnUrl);
    }

    useEffect(() => {
        let ignore = false

        // need to unsee the notification first
        const pdfFileInfoCdnUrl = otherData?.pdfFileInfo?.cdnUrl;
        const printersInfo = otherData?.printersInfo;

        if (pdfFileInfoCdnUrl && printersInfo) {
            const selectedPrinter = printersInfo?.selectedPrinter;
            if (selectedPrinter) {
                // set the notif to seen fisrt
                seeNotification(notification.id).then(response => {
                    console.log(response);
                    if (response?.seen) {
                        if (!ignore) {
                            printPdfUsingPrinter(pdfFileInfoCdnUrl, selectedPrinter.printerName, 1, t('print.fail'), t("print.start"));
                        }
                    }
                });
            }
        }

        return () => {
            ignore = true;
        }
    }, []);


    return (
        <>
            <Toast.Header closeButton={true}>
                <img
                    src="holder.js/20x20?text=%20"
                    className="rounded me-2"
                    alt=""
                />
                <strong className="me-auto">{t(subject)}</strong>
            </Toast.Header>
            <Toast.Body>
                <span>{t(body)}</span><br></br>
                { pdfFileName ? <span>Filename: {pdfFileName}</span> : <></>} <br></br>
                {/* <button onClick={() => {triggerOpenPrintConfirmation(otherData)}} className="btn btn-primary rounded-1 m-2" type="submit">{t('notification.printcta')}</button> */}
                {/* <Button
					id="previewButton"
					variant="outline-primary"
					onClick={downloadPdfHandler}
				>
					{t('notification.downloadcta')}
				</Button> */}
            </Toast.Body>
        </>
    )
}

export default PdfPrintGenerationSuccess;