function AlignRight({ size }: { size: number }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height={size} viewBox="0 0 24 24">
      <path
        d="M20 3C19.8011 3 19.6103 3.07902 19.4697 3.21967C19.329 3.36032 19.25 3.55109 19.25 3.75V20.25C19.25 20.4489 19.329 20.6397 19.4697 20.7803C19.6103 20.921 19.8011 21 20 21C20.1989 21 20.3897 20.921 20.5303 20.7803C20.671 20.6397 20.75 20.4489 20.75 20.25V3.75C20.75 3.55109 20.671 3.36032 20.5303 3.21967C20.3897 3.07902 20.1989 3 20 3ZM15 6H6C5.46957 6 4.96086 6.21071 4.58579 6.58579C4.21071 6.96086 4 7.46957 4 8V9C4 10.1 4.9 11 6 11H15C15.5304 11 16.0391 10.7893 16.4142 10.4142C16.7893 10.0391 17 9.53043 17 9V8C17 7.46957 16.7893 6.96086 16.4142 6.58579C16.0391 6.21071 15.5304 6 15 6ZM15 13H11C10.4696 13 9.96086 13.2107 9.58579 13.5858C9.21071 13.9609 9 14.4696 9 15V16C9 17.1 9.9 18 11 18H15C15.5304 18 16.0391 17.7893 16.4142 17.4142C16.7893 17.0391 17 16.5304 17 16V15C17 14.4696 16.7893 13.9609 16.4142 13.5858C16.0391 13.2107 15.5304 13 15 13ZM15 7.5C15.28 7.5 15.5 7.72 15.5 8V9C15.5 9.13261 15.4473 9.25979 15.3536 9.35355C15.2598 9.44732 15.1326 9.5 15 9.5H6C5.86739 9.5 5.74021 9.44732 5.64645 9.35355C5.55268 9.25979 5.5 9.13261 5.5 9V8C5.5 7.72 5.72 7.5 6 7.5H15ZM15 14.5C15.28 14.5 15.5 14.72 15.5 15V16C15.5 16.1326 15.4473 16.2598 15.3536 16.3536C15.2598 16.4473 15.1326 16.5 15 16.5H11C10.8674 16.5 10.7402 16.4473 10.6464 16.3536C10.5527 16.2598 10.5 16.1326 10.5 16V15C10.5 14.72 10.72 14.5 11 14.5H15Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default AlignRight
