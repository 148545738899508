import { useEffect } from "react";
import { useState } from "react";
import "./Cart.css";
import { getCart, resetCart, setUpdatedCartItemsAndCount } from "../../services/cart";
import GroupedByFormatCart from "../../components/Cart/GroupedByFormatCart";
import Actions from "../../components/Cart/Actions";
import { useAuth } from "../../contexts/AuthContext";

// Nw Utils Functions
import { compareAtLeastOneKey } from "../../lib/functions";

// Nw Cart Placeholder
import CartPlaceholder from "../../components/Cart/CartPlaceholder";

import { CartContext } from "../../contexts/CartContext";

function Cart() {
	const { subscriptionData } = useAuth();
	const printAccessFromSubscriptionData = subscriptionData?.subscriptionRights?.allowPrint;
    const [cartData, setCartData] = useState({});
	const [isCartEmpty, setIsCartEmpty] = useState(false);
    const [selectedPrinters, setSelectedPrinters] = useState({});
    const [ isResetting, setIsResetting ] = useState(false);
    const [isOrderingGeneral, setIsOrderingGeneral] = useState(false);
	const [allowGlobalPrint, setAllowGlobalPrint] = useState(printAccessFromSubscriptionData);

    const isOrderingGeneralState = {
        value: isOrderingGeneral,
        update: setIsOrderingGeneral
    }

    const updateSelectedPrinterForFormat = (format, printerId, isChecked) => {
        setSelectedPrinters(prevSelectedPrinters => {
            let newSelectedPrinters = {...prevSelectedPrinters};
			if (isChecked) {
				// Add or update the printer ID for the format
				newSelectedPrinters[format] = printerId;
			} else {
				// Remove the format from the object if it exists
				if (newSelectedPrinters.hasOwnProperty(format)) {
					delete newSelectedPrinters[format];
				}
			}
            return newSelectedPrinters;
        })
    }

	useEffect(() => {
		if (selectedPrinters && cartData?.cartData?.groupedCartItemsByFormat) {
			const isSame = compareAtLeastOneKey(cartData?.cartData?.groupedCartItemsByFormat, selectedPrinters);
			setAllowGlobalPrint(isSame && printAccessFromSubscriptionData);
		}
		
	}, [selectedPrinters, cartData])
	

    useEffect(() => {
        async function getCartData() {
            try {
                const fetchedCartData = await getCart();
                if (fetchedCartData) {
					setCartData(fetchedCartData);
					setIsCartEmpty((Array.isArray(fetchedCartData.cartData?.cartItems) && fetchedCartData.cartData?.cartItems.length === 0) || !fetchedCartData.cartData.hasOwnProperty('cartItems'));
				}
            } catch (e) {
                console.error(e);
            }
        }

        getCartData();
    }, []);

    useEffect(() => {
        if (Object.keys(cartData).length > 0 && 'updatedCartData' in cartData) {
            setUpdatedCartItemsAndCount(cartData.updatedCartData);
			setIsCartEmpty((Array.isArray(cartData.updatedCartData?.cartItems) && cartData.updatedCartData?.cartItems.length === 0) || !cartData.updatedCartData.hasOwnProperty('cartItems'));
        }
    }, [cartData]);

    const orderAction = async (convertCartToOrder, type='download') => {
        await convertCartToOrder(cartData['cartData']['cart']['id'], selectedPrinters, null, type);
        try {
            const fetchedCartData = await getCart();
            if (fetchedCartData) {
                setCartData(fetchedCartData);
                setIsCartEmpty((Array.isArray(fetchedCartData.cartData?.cartItems) && fetchedCartData.cartData?.cartItems.length === 0) || !fetchedCartData.cartData.hasOwnProperty('cartItems'));
            }
        } catch (e) {
            console.error(e);
        }
    }

    const handleResetCart = async () => {
        setIsResetting(true);
        const data = await resetCart(cartData['cartData']['cart']['id']);
        const fetchedCartData = await getCart();
        if (fetchedCartData === undefined || fetchedCartData == null || fetchedCartData.length === 0 || fetchedCartData.updatedCartData.cartItemsCount === 0){
            setCartData({});
			setIsCartEmpty(true);

            const updatedCartData = {
                cartItems: [],
                cartItemsCount: 0
            }

            setUpdatedCartItemsAndCount(updatedCartData);
            setIsResetting(false);
        };
    }

    return (
        <div 
			className="d-flex flex-column justify-content-between py-2 px-3 mt-1" 
			style={{
				minHeight: '85svh'
			}}
		>
            <CartContext.Provider
                value={{
                    allowPrint: allowGlobalPrint,
                    allowPrintFromSubscription: printAccessFromSubscriptionData
                }}
            >
                { 
                    cartData && cartData.cartData && cartData.cartData.groupedCartItemsByFormat ?
                    <>

                        <div className="nw-main-cart-container">
                            <Actions className="my-3 py-3" isOrderingGeneralState={isOrderingGeneralState} orderAction={orderAction} resetCart={handleResetCart} isResetting={isResetting}/>
                            <GroupedByFormatCart 
                                isOrderingGeneralState={isOrderingGeneralState}
                                updatePrinter={updateSelectedPrinterForFormat} 
                                groupedCartItemsByFormat={cartData.cartData.groupedCartItemsByFormat}
                                updateCart={setCartData}
                                cartId={cartData.cartData.cart.id}
                                selectedPrinters={selectedPrinters}
                            />
                        </div>
                        <Actions className="my-1 py-1" isOrderingGeneralState={isOrderingGeneralState} orderAction={orderAction} resetCart={handleResetCart} isResetting={isResetting}/>
                    </>
                    :
                    (
                        <CartPlaceholder isCartEmpty={isCartEmpty}/>
                    )
                }
            </CartContext.Provider>
        </div>
    )
}

export default Cart;