import React from 'react';
import './lib/types.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js'
import { AuthProvider } from './contexts/AuthContext';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import AppRoutes from './_routes/Routes';
import "../src/assets/styles/global.css"
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';

function App() {
	return (		
		<AuthProvider>
            <I18nextProvider i18n={i18n}>
                <AppRoutes />
            </I18nextProvider>
		</AuthProvider>
	);
}

export default App;
