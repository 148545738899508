function Opacity({ size }: { size: number }) {
  return (
    <svg height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.23176 7.20527L5.0791 6.24609C4.41048 7.04781 3.88715 7.96022 3.53271 8.94214L4.94336 9.4521C5.2385 8.63374 5.67454 7.87334 6.23176 7.20527V7.20527Z"
        fill="currentColor"
      />
      <path
        d="M4.50064 11.9993C4.50067 11.5665 4.5378 11.1344 4.61163 10.7079L3.13349 10.4514C2.9555 11.4792 2.9555 12.5299 3.13349 13.5577L4.61088 13.2997C4.53684 12.8702 4.49996 12.4351 4.50064 11.9993V11.9993Z"
        fill="currentColor"
      />
      <path
        d="M9.38078 4.97009L8.85657 3.5647C7.87821 3.92951 6.97113 4.46248 6.17627 5.13958L7.1512 6.28249C7.81239 5.71867 8.5669 5.27454 9.38078 4.97009V4.97009Z"
        fill="currentColor"
      />
      <path
        d="M6.18359 18.8671C6.97948 19.543 7.8873 20.0747 8.86614 20.4382L9.3911 19.0321C8.57539 18.7295 7.81901 18.2863 7.15627 17.7227L6.18359 18.8671Z"
        fill="currentColor"
      />
      <path
        d="M4.95042 14.5549L3.54053 15.0656C3.89652 16.047 4.42082 16.9588 5.08991 17.7602L6.23807 16.7988C5.68174 16.1313 5.246 15.372 4.95042 14.5549V14.5549Z"
        fill="currentColor"
      />
      <path
        d="M12.0002 3C11.4541 3.00093 10.9092 3.05113 10.3721 3.14999L10.642 4.62588C11.09 4.54285 11.5446 4.50068 12.0002 4.49989V19.4988C11.5447 19.499 11.0901 19.4578 10.642 19.3758L10.3721 20.8487C11.6697 21.086 13.0037 21.0354 14.2796 20.7005C15.5556 20.3655 16.7423 19.7544 17.7561 18.9103C18.7698 18.0661 19.5858 17.0096 20.1462 15.8154C20.7067 14.6212 20.998 13.3185 20.9995 11.9993C20.9969 9.61335 20.048 7.32583 18.3608 5.63869C16.6737 3.95155 14.3862 3.00258 12.0002 3V3Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default Opacity
