import React from 'react';
import { CheckCircleFill, PinAngleFill } from 'react-bootstrap-icons';
/**
 * 
 * @param {PreviewListItemProps} props 
 * @returns {JSX.Element} The rendered PreviewListItem component.
 */
const PreviewListItem = (props) => {

    const showAndReturnTagData = props?.showAndReturnTag ?? 
        { 
            showTag: false, 
            tagElement: <PinAngleFill/>,
            position: 'top-left'
        }

    return (
        <div onClick={props.onClick} className={`preview-list-item-container`}>
            <div className={`preview-list-item-wrapper nw-responsive-font-size-xl ${props.isSelected ? 'is-selected' : ''}`}>
                <img className={`preview-list-item-image`} src={props.imageSrc} alt="" loading="lazy" />
                {props.isSelected && <CheckCircleFill className="preview-list-item-checked" size={'1em'}/>}
                {
                    showAndReturnTagData?.showTag && (
                        <div className={`preview-list-item-tag-wrapper d-flex justify-content-center align-items-center
                                ${showAndReturnTagData?.position ?? 'top-left'}                             
                            `}
                        >
                            { 
                                React.cloneElement(showAndReturnTagData?.tagElement ?? <PinAngleFill/>, { 
                                    className: `preview-list-item-tag`,
                                    size: '1em'
                                }) 
                            }
                        </div>
                    )
                }
            </div>
            <div className={`preview-list-item-text ${props.textCenter ? 'center' : ''}`}>
                { props.text }
            </div>
        </div>
    );
}

export default PreviewListItem;