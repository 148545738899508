import {useContext} from 'react';
import MainLayoutContext from '../contexts/MainLayoutContext';
import { PlatformTypes } from '../constants/nw/nw-constants';

/**
 * @typedef MainLayoutContextValue
 * @property {Function} setPageTitle - Function Set State Action for Page Title
 * @property {React.Dispatch<PageTitleAdditionalContent>} setPageTitleAdditionalContent -  Function to set Additional content new the 
 * page title
 * @property {React.Dispatch<string|undefined|null>} setNwDocumentTitle - The document title to dynamic add in the template
 * @property {Function} setShowFooter - Function to set whether to show Footer or not
 * @property {PlatformTypes} currentPlatform - has the current platform currently we are
 * @property {boolean} isTouchScreen - checks whether we are in touch screen or not
 * @property {Function} setFocusBlockData - allows setting a focus block data
 * @property {string} locale - has the current locale of page
 */

/**
 * Custom hook to access MainLayoutContextValue context.
 *
 * @returns {MainLayoutContextValue} MainLayoutContextValue context values and actions.
 */
export const useMainLayoutContext = () =>{
    return useContext(MainLayoutContext);
}