import { useState, useEffect } from "react";
import { 
    getSocialMediaPackGroupPosters,
    downloadSocialMediaPosterImageById,
    deleteSocialMediaPosterById,
    downloadSocialMediaFreePosterById,
    deleteSocialMediaFreePosterById,
    createSocialMediaPackGroupCategory,
    editSocialMediaPackGroupCategory,
    deleteSocialMediaPackGroupCategory,
    getSocialMediaPackGroupCategoriesByFilters,
} from "../../services/social_media";
import CategorySideFilter from '../../components/SocialMedia/CategorySideFilter/CategorySideFilter';
import { Row, Col, Button } from 'react-bootstrap';
import ListCard from './ListCard/ListCard';
import { useTranslation } from 'react-i18next';
import { 
    EyeFill,
    Download,
    XLg,
    PencilSquare,
    Eye
} from 'react-bootstrap-icons';
import ResponsivePagination from 'react-responsive-pagination';
import { useParams, useNavigate } from 'react-router-dom';
import NwCustomModal from "../../components/_utilityComponents/NwCustomModal/NwCustomModal";
import NwImage from "../../components/_utilityComponents/NwImage/NwImage";
import { useAuth } from "../../contexts/AuthContext";


export default function CampaignPostersPage() {
    const { t } = useTranslation();
    const [socialMediaPosters, setSocialMediaPosters] = useState([]);
    const [currentPaginationPage, setCurrentPaginationPage] = useState(1);
    const [filters, setFilters] = useState({});
    const [pageCount, setPageCount] = useState(0);
    const itemsPerPage = 12;
    const params = useParams();
    const socialMediaPackGroupId = params?.id;

    const [categories, setCategories] = useState(null);
    const [categoriesLoading, setCategoriesLoading] = useState(true);
    const navigate = useNavigate();
    const { userNw } = useAuth();

    useEffect(() => {
        getSocialMediaPackGroupPosters(socialMediaPackGroupId).then(response => {
            if (response) {
                setSocialMediaPosters(response?.all);
                setPageCount(Math.ceil(response?.totalItems / itemsPerPage));
            }
        }).catch(e => console.log(e));
    }, [socialMediaPackGroupId])

    const handleDownloadSocialMediaPoster = async (posterId) => {
        const responseData = await downloadSocialMediaPosterImageById(posterId);
        if (responseData) {
            const url = URL.createObjectURL(responseData);
			const a = document.createElement("a");
			a.href = url;
	
			a.download = "media.zip";
			document.body.appendChild(a);
			a.click();
	
			document.body.removeChild(a);
			URL.revokeObjectURL(url);
        }
    }

    const handleDeleteSocialMediaPoster = async (posterId) => {
        const success = await deleteSocialMediaPosterById(posterId);
        if (success) {
            getSocialMediaPackGroupPosters(socialMediaPackGroupId).then(response => {
                if (response) {
                    setSocialMediaPosters(response?.all);
                    setPageCount(Math.ceil(response?.totalItems / itemsPerPage));
                }
            }).catch(e => console.log(e));
        }
    }

    const handleDownloadSocialMediaFreePoster = async (freePosterId) => {
        const responseData = await downloadSocialMediaFreePosterById(freePosterId);
        if (responseData) {
            const url = URL.createObjectURL(responseData);
			const a = document.createElement("a");
			a.href = url;
	
			a.download = "media.png";
			document.body.appendChild(a);
			a.click();
	
			document.body.removeChild(a);
			URL.revokeObjectURL(url);
        }
    }

    const handleDeleteSocialMediaFreePoster = async (freePosterId) => {
        const success = await deleteSocialMediaFreePosterById(freePosterId);
        if (success) {
            getSocialMediaPackGroupPosters(socialMediaPackGroupId).then(response => {
                if (response) {
                    setSocialMediaPosters(response?.all);
                    setPageCount(Math.ceil(response?.totalItems / itemsPerPage));
                }
            }).catch(e => console.log(e));
        }
    }

    const updateCategories = async () => {
        setCategoriesLoading(true);
        getSocialMediaPackGroupCategoriesByFilters().then(async response => {
            setCategories(response?.items)
            setCategoriesLoading(false);
        });
    } 

    useEffect(() => {
        updateCategories()
    }, []);

    const handleCategorySelection = (categoryId) => {
        navigate(`/social-media/campaigns?categoryId=${categoryId}`);
    }

    return (
        <>
            <div className="d-flex px-3 py-3">
                <CategorySideFilter 
                    categories={categories} 
                    createNewCategory={createSocialMediaPackGroupCategory} 
                    updateCategories={updateCategories} 
                    categoriesLoading={categoriesLoading} 
                    handleCategorySelection={handleCategorySelection}
                    deleteCategory={deleteSocialMediaPackGroupCategory}
                    editCategory={editSocialMediaPackGroupCategory}
                />
                <Row className='w-75 gx-2'>     
                    {
                        socialMediaPosters.map((socialMediaPoster, index) => {
                            const socialMediaPosterId = socialMediaPoster.id;
                            const editAction = {
                                title: "Edit",
                                icon: <PencilSquare/>,
                                type: "link",
                            };
                            
                            const downloadAction = {
                                title: 'Download',
                                type: "button",
                                icon: <Download/>,
                            }

                            const viewAction = {
                                title: "View",
                                icon: <EyeFill/>,
                                type: "custom",
                            }

                            const deleteAction = {
                                title: "Delete",
                                type: "button",
                                icon: <XLg/>,
                            }
                            if (socialMediaPoster.type === "social_media_free_poster") {
                                downloadAction.onClick = () => handleDownloadSocialMediaFreePoster(socialMediaPosterId);
                                deleteAction.onClick = () => handleDeleteSocialMediaFreePoster(socialMediaPosterId);
                                editAction.href = `/social-media/free-editor/edit/pack/${socialMediaPoster.socialMediaFreePackId}?focus_scene_id=${socialMediaPoster.sceneId}`;
                                const showModalPreviewComponent = <ShowPreviewModal imageUrl={socialMediaPoster?.imageMainUrl}/>
                                viewAction.component = showModalPreviewComponent;
                            } else if (socialMediaPoster.type === "social_media_poster") {
                                downloadAction.onClick = () => handleDownloadSocialMediaPoster(socialMediaPosterId);
                                deleteAction.onClick = () => handleDeleteSocialMediaPoster(socialMediaPosterId);
                                editAction.href = `/social-media/editor/edit/social_media_product/${socialMediaPoster.socialMediaProductId}?company_id=${userNw?.companyId}`;
                                const showModalPreviewComponent = <ShowPreviewModal imageUrl={socialMediaPoster?.imageMainUrl}/>
                                viewAction.component = showModalPreviewComponent;
                            }

                            const actions = [
                                downloadAction,
                                editAction,
                                viewAction,
                                deleteAction
                            ]

                            return (
                                <Col className='flex-grow-0' key={socialMediaPosterId}>
                                    <ListCard 
                                        key={socialMediaPosterId}
                                        title={socialMediaPoster?.title} 
                                        imgSrc={socialMediaPoster?.thumbnailPreviewUrl}
                                        actions={actions}    
                                    />
                                </Col>
                            )
                        })
                    }
                </Row>
            </div>
            <ResponsivePagination
                previousLabel={t("back")} 
                nextLabel={t("next")} 
                current={currentPaginationPage}
                total={pageCount}
                onPageChange={setCurrentPaginationPage}
            />
        </>
    )
}

function ShowPreviewModal ({imageUrl}) {
    const [displayPreview, setDisplayPreview] = useState(false);

    return (
        <NwCustomModal
            onShow={() => setDisplayPreview(true)}
            onClose={() => setDisplayPreview(false)}
            customButton={<Button className="list-card-btn rounded-1 nw-responsive-font-size-sm"><Eye size={22}/></Button>}
            modalProps={{
                body: {
                    className: 'd-flex'
                }
            }}
        >
            <div className="d-flex">
                {displayPreview && <NwImage imgClassName="w-100 h-100 object-fit-contain " src={imageUrl} />}
            </div>
        </NwCustomModal>
    )                 
}