import React, { useEffect, useState } from "react";
import { getTemplateGroups, handleSearchData, handleSortData, handleFetchMoreData, getDefaultTemplateGroupBodyData } from "../../../services/posters";
import { getTemplateGroupMetadata } from "../../../services/template_group";
import { getFreePosterWithMetadataById } from "../../../services/free_poster";
import { loadTemplateFonts } from "../../../utils/fonts";
import { loadVideoEditorAssets } from "../../../utils/video";
import useDesignEditorContext from "../hooks/useDesignEditorContext";
import useEditorUseContext from '../hooks/useEditorUseContext';
import { useEditor } from "@layerhub-io/react";

export const TemplateGroupContext = React.createContext<any>({});

export const TemplateGroupProvider = ({mode, freePosterId, children} : any) => {
	const editor = useEditor();

	const { use, setCanvasClassNames,setCanvasStyles } = useEditorUseContext();
	const {setScenes, setCurrentDesign} = useDesignEditorContext();
	const [title, setTitle] = useState("");
	const [chartesGraphiquesOptions, setChartesGraphiquesOptions] = useState(null);
	const [ originalChartesGraphiqueOption, setOriginalChartesGraphiqueOption ] = useState(null);
	const [selectedOptionChartesGraphique, setSelectedOptionChartesGraphique] = useState<any>(null); // To keep track of the selected option
	const [isChartesGraphiquesLoading, setIsChartesGraphiquesLoading ] = useState(true);
	const [chartesGraphiquesBodyData, setChartesGraphiquesBodyData] = useState<any>(null);
	const [templateGroupMetadata, setTemplateGroupMetadata] = useState(null);
	const [currentFormat, setCurrentFormat] = useState({
		"value": 5,
		"label": "A4",
		"size" : {
			"width": 210,
			"height": 297,
			"unit": "mm"
		},
		"sizeInPX": {
			"width" : 794,
			"height" : 1123,
			"unit": "px"
		},
		active: true
	});
	const [currentOrientation, setCurrentOrientation] = useState({
		"value": "portrait",
		"label": "portrait"
	});

	useEffect(() => {
		if(use !== "free_poster") return;

		setCanvasClassNames({
			previewWrapper: 'h-100',
			canvasWrapper: 'h-100'
		})

		setCanvasStyles({
			container: {
				display: 'flex',
				height: '100%',
				alignItems: 'stretch'
			}
		})

		async function handleEditorMode() {
			let fetchedtemplateGroupMetadata = null;
			fetchedtemplateGroupMetadata = await getTemplateGroupMetadata();
			const templateGroupOptions = await getTemplateGroups();
			setChartesGraphiquesOptions(templateGroupOptions?.items);
			setChartesGraphiquesBodyData(getDefaultTemplateGroupBodyData());
			setTemplateGroupMetadata(fetchedtemplateGroupMetadata);
			if (mode === "edit" && freePosterId !== 0) {
				(async function getFreePosterData(freePosterId: number){
					const freePosterData = await getFreePosterWithMetadataById(freePosterId);
					if (freePosterData === null) return;
					if (freePosterData.title) {
						setTitle(freePosterData.title);
					}
					if (freePosterData.templateGroupId !== null) {
						fetchedtemplateGroupMetadata = await getTemplateGroupMetadata(freePosterData.templateGroupId);
						setTemplateGroupMetadata(fetchedtemplateGroupMetadata);

						for (const templateGroup of templateGroupOptions.items) {
							if (templateGroup.id === freePosterData.templateGroupId) {
								// setSelectedOptionChartesGraphique(templateGroup);
								setOriginalChartesGraphiqueOption(templateGroup);
							}
						}
					}

					const freePosterFormatId = freePosterData.formatId;
					const orientation = freePosterData.orientation;
					const formats = fetchedtemplateGroupMetadata?.format?.select;
					if (formats) {
						for (const format of formats) {
							if (format.value === freePosterFormatId) {
								setCurrentFormat(format)
							}
						}
					}

					if (orientation) {
						setCurrentOrientation({
							value: orientation,
							label: orientation,
						})
					}

					const loadGraphicTemplate = async (payload: any) => {
						const scenes = []
						const { scenes: scns, ...design } = payload
					
						for (const scn of scns) {
						const scene: any = {
							name: scn.name,
							frame: payload.frame,
							id: scn.id,
							layers: scn.layers,
							metadata: {},
						}
						const loadedScene = await loadVideoEditorAssets(scene)
						await loadTemplateFonts(loadedScene)
					
						// const preview = (await editor.renderer.render(loadedScene)) as string
						const preview = null;
						scenes.push({ ...loadedScene, preview })
						}
					
						return { scenes, design }
					}
					const handleImportTemplate = async (data: any) => {
						let template
						if (data.type === "GRAPHIC") {
							template = await loadGraphicTemplate(data)
						}
						//   @ts-ignore
						setScenes(template.scenes)
						//   @ts-ignore
						setCurrentDesign(template.design)
					}
				
					handleImportTemplate(freePosterData.layerhubJson);
				})(freePosterId);
			} else {
				editor.frame.resize({
					width : 794,
					height : 1123,
				})
			}
		}

		handleEditorMode();
		
	}, [setChartesGraphiquesOptions, setIsChartesGraphiquesLoading, setTemplateGroupMetadata, freePosterId, mode, setCurrentDesign, setScenes, use]);

	const context = {
		chartesGraphiquesOptions,
		setChartesGraphiquesOptions,
		selectedOptionChartesGraphique,
		originalChartesGraphiqueOption,
		setOriginalChartesGraphiqueOption,
		chartesGraphiquesBodyData,
		setChartesGraphiquesBodyData,
		setSelectedOptionChartesGraphique,
		isChartesGraphiquesLoading,
		setIsChartesGraphiquesLoading,
		templateGroupMetadata,
		setTemplateGroupMetadata,
		currentFormat,
		setCurrentFormat,
		currentOrientation,
		setCurrentOrientation,
		title,
		setTitle,
		getTemplateGroups,
		handleSearchData,
		handleSortData,
		handleFetchMoreData,
	}

    return <TemplateGroupContext.Provider value={context}>{children}</TemplateGroupContext.Provider>
}