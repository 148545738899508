import useEditorUseContext from "../../hooks/useEditorUseContext";
import Canvas from '../Canvas/Canvas';

export default function EditorContent() {
    const {currentEditorContent, editorContents, canvasClassNames, canvasStyles} = useEditorUseContext();
    const EditorContentComponent = editorContents[currentEditorContent["content"]];
    const hideCanvas = currentEditorContent.hideCanvas;

    return (
        <>
            <EditorContentComponent />
            <Canvas 
                hideCanvas={ hideCanvas }
                classNames={canvasClassNames}
                styles={canvasStyles}
            />
        </>
    )
}