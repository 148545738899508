import { useCallback, useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { uploadCustomImages, deleteSingleCustomImage, UPLOAD_STATES } from "../../../services/custom_images";
import StyledDropzone from "../NwDropzone/StyledDropzone";
import NwCustomModal from "../NwCustomModal/NwCustomModal";
import NwDropzonePreviewItem from "./NwDropzonePreviewItem";
import { useTranslation } from "react-i18next";
import './NwCustomImageDropzone.css';

const NwCustomImageDropzone = ({
		initialUploadedInfos = [],
		handleFilesUploaded,
        disabled = false,
		maxFiles,
		type ='product_image',
		productId = null,
        isDebug = false,
        showPreviewOnDrop = false,
		handleFilesOnDrop = null,
		handleFilesDelete = null,
        allowDelete = true,
	}) => {
    const { t } = useTranslation();
    const [fileUploads, setFileUploads] = useState([]);
    const [ uploadedFile, setFileUploaded ] = useState([]);
    const [showPreview, setShowPreview] = useState(false);

    const onDrop = useCallback(acceptedFiles => {
        if (showPreviewOnDrop){
            setShowPreview(true);
        } else {
            setShowPreview(false);
        }
		
		const filesWithProgress = acceptedFiles.map(file => ({
			file,
            preview: URL.createObjectURL(file),
			progress: 0,
			uploadedState: UPLOAD_STATES.IN_PROGRESS
		}));
		setFileUploads(filesWithProgress);
		if (handleFilesOnDrop == null) {
			filesWithProgress.forEach(fileWithProgress => handleProductImageUpload(fileWithProgress));
		} else {
			handleFilesOnDrop(filesWithProgress); 
		}
    }, []);

    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isFocused,
        isDragAccept,
        isDragReject
    } = useDropzone({onDrop, maxFiles: maxFiles});

    //// Handling State Change for File Uploads
    useEffect(() => {
        if (fileUploads && fileUploads.length > 0){
            const allUploadsSuccessful = fileUploads.every(fileUpload => fileUpload.uploadedState === UPLOAD_STATES.SUCCESS);

            if (isDebug){
                console.log("file uploads:", fileUploads);
            }

            if (allUploadsSuccessful && fileUploads.length > 0) {
                if (handleFilesUploaded)
                {
                    handleFilesUploaded(fileUploads);
                }
                
                if (maxFiles === 1 && fileUploads) {
                    // If single upload allow, need to store previous uploaded file, as it will be deleted when it is overwritten/replaced
                    setFileUploaded(fileUploads);
                }
                setShowPreview(true)
            }
        } else {
            if (handleFilesUploaded)
            {
                handleFilesUploaded(null);
            }
        }
    }, [fileUploads])

    //// UseEffect to handle initial files
    useEffect(() => {
        // Process initial uploaded info to match the expected format
        if (initialUploadedInfos && initialUploadedInfos?.length > 0)
        {
            const processedInitialFiles = initialUploadedInfos?.map((uploadedInfo, index) => ({
                file: {
                    name: uploadedInfo?.filename ?? `initial-file-${index}`
                }, // Dummy file object since the actual file is not available
                progress: 100, // Assuming initial files are already uploaded
                uploadedState: UPLOAD_STATES.SUCCESS,
                uploadedInfo: uploadedInfo // Actual uploaded info
            }));

            // Set initial state
            setFileUploads(processedInitialFiles);
        }
    }, [initialUploadedInfos]);
    

    // Function called when Image/File dropped
    const handleProductImageUpload = (fileWithProgress) => {
        uploadCustomImages(
            [fileWithProgress.file],
            productId,
            maxFiles === 1 ? uploadedFile[0]?.uploadedInfo ?? null : null, //if single file upload allowed, pass previous uploaded image info to delete
            type,
            (progress, fileWithProgress) => {
                onProgress(progress, fileWithProgress)
            },
            (uploadedImageInfo, chosenImages) => {
                onSuccess(uploadedImageInfo, chosenImages)
            },
            (error, fileWithError) => {
                onError(error, fileWithError);
            }
        );
    }
    
    // Function thats tracks progress and change corresponding progress of files/images
    /**
     * The function `onProgress` updates the progress and state of a file upload in a list of current
     * uploads based on the progress value and file name provided.
     */
    function onProgress(progress, fileWithProgress) {
        setFileUploads(currentUploads => 
            currentUploads.map(f => {
                if (f.file.name === fileWithProgress[0].name) {
                    let newState = { ...f, progress: progress };
                    if (progress >= 100) {
                        newState.uploadedState = UPLOAD_STATES.PROCESSING;
                    }
                    return newState;
                } else {
                    return f;
                }
            })
        );
    }

    // Function called on File Uploaded Successfully
    /**
     * The `onSuccess` function updates the uploaded state and information of a specific file in a list of
     * file uploads based on the chosen image.
     */
    const onSuccess = (uploadedImageInfo, chosenImages) => {
        setFileUploads(currentUploads =>
            currentUploads.map(fileUpload => {
                if (fileUpload.file.name === chosenImages[0].name) {
                    // Update the specific file's uploaded state and info
                    return {
                        ...fileUpload,
                        uploadedState: UPLOAD_STATES.SUCCESS,
                        uploadedInfo: uploadedImageInfo[0] // Assuming the backend returns info like this
                    };
                }
                return fileUpload;
            })
        );
    }

    //Function called if error arises when uploading
    /**
     * The `onError` function updates the uploaded state of a specific file to indicate an error in a file
     * upload process.
     */
    const onError = (error, fileWithError) => {
        setFileUploads(currentUploads =>
            currentUploads.map(fileUpload => {
                if (fileUpload.file.name === fileWithError[0].name) {
                    // Update the specific file's uploaded state to indicate error
                    return {
                        ...fileUpload,
                        uploadedState: UPLOAD_STATES.ERROR
                    };
                }
                return fileUpload;
            })
        );
    }

    // Make the function async to handle the asynchronous operations
    /**
     * The function `clearProductImage` asynchronously clears a product image based on certain conditions
     * and updates the success status accordingly.
     * @returns The `clearProductImage` function returns an object with a `success` property indicating
     * whether the image clearing process was successful or not. The object is structured as follows:
     * ```javascript
     * {
     *     success: true // or false based on the outcome of the image clearing process
     * }
     * ```
     */
    const clearProductImage = async (fileUpload) => {
        let clearImageResponse = {
            success: false
        };

        if (isDebug){
            console.log("File Upload On Delete", fileUpload);
        }

        try {
            let response = {
                success: false
            };
            
            if (fileUpload?.uploadedInfo) // check if there is uploadedInfo first
            {
                if (handleFilesDelete == null) {
                    // Await the promise to resolve
                    response = await deleteSingleCustomImage(fileUpload.uploadedInfo);
                } else {
                    // Await the custom delete handler's promise to resolve
                    response = await handleFilesDelete(fileUpload);
                }
            }

            // Check the response and update the success status accordingly
            if (fileUpload?.file) {
                if (showPreviewOnDrop && !fileUpload?.uploadedInfo){ //if there is showOnPreview and there is no uploadedInfo, then after check if there is a file, force response status to allow delete
                    response.success = true;
                }

                if (response && response.success) {
                    clearImageResponse.success = true; // Update the response object on success

                    // Update the file uploads state to remove the cleared image
                    setFileUploads(currentUploads =>
                        currentUploads.filter(f => f.file.name !== fileUpload.file.name)
                    );
                }
            }
        } catch (error) {
            // Handle any errors that occurred during the process
            console.error("Error clearing product image:", error);
            // You might want to add an error or message field to clearImageResponse to convey the error
        }

        // Return the updated response object
        return clearImageResponse;
    };


    return (
        <div>
            <NwCustomModal 
                title={t('nwModal.openModalBtnTitle.uploadImage')}
                defaultButtonProps={{
                    disabled: disabled,
                    className: 'nw-responsive-font-size'
                }}
            >
                <div style={{
                    width: '30vw'
                }}>
                    <StyledDropzone 
                        getRootProps={getRootProps}
                        getInputProps={getInputProps}
                        isFocused = {isFocused}
                        isDragActive = {isDragActive}
                        isDragAccept = {isDragAccept}
                        isDragReject = {isDragReject}
                        fileUploads={fileUploads}
                    />
                </div>
            </NwCustomModal>
            <div className="mt-3">
                {showPreview && fileUploads?.map((fileUpload, idx) => {
                    if (fileUpload?.uploadedInfo?.imageCdnUrl || fileUpload?.preview)
                    {
                        return (
                            <NwDropzonePreviewItem
                                isDebug={isDebug}
                                isDisabled={disabled}
                                key={idx}
                                fileUploaded={fileUpload}
                                clearProductImage={clearProductImage}
                                allowDelete = {allowDelete}
                            />
                        )
                    }

                })}
            </div>
        </div>
    )
}

export default NwCustomImageDropzone;