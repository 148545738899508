import { useState } from "react";
import { 
    Form, 
    Badge, 
    Col, 
    Button,
	Row,
    OverlayTrigger,
    Tooltip,
    Card
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Eye,Pencil,XLg,Files,Trash} from "react-bootstrap-icons";
import './FreePosterCard.css';

// Icons
import { NwCartIcon } from "../../_utilityComponents/NwIcons/NwIcons";
import NwCustomModal from "../../_utilityComponents/NwCustomModal/NwCustomModal";
import NwImage from "../../_utilityComponents/NwImage/NwImage";


const FreePosterCard = (props) => {
    const [isHover, setIsHover] = useState(false);
	const iconSize = 22;
	const isDebug = props.isDebug || false;
    const [displayPreview, setDisplayPreview] = useState(false);

	const handleMouseOver = () => {
		setIsHover(true);  // Set hover state to true when mouse is over the component
	};
	
	const handleMouseOut = () => {
		setIsHover(false);  // Set hover state to false when mouse leaves the component
	};

    const { t } = useTranslation();
    return (
		<Col key={`product-card-${props.data.id}`} className="list-free-poster-item">
			<Card 
				className={`list-free-poster-card border-0 ${isHover ? 'shadow-lg onhover' : 'shadow'} position-relative d-flex justify-content-center align-items-center mb-0 rounded-1`} 
				id={`product-card-${props.data.id}`}
				onMouseOver={handleMouseOver}
                onMouseOut={handleMouseOut}
			>
				<div className="list-free-poster-item-badge-container">
					<OverlayTrigger overlay={<Tooltip >Format</Tooltip>}>
						<Badge bg="success" className='list-free-poster-item-badge list-free-poster-item-format-badge shadow'>{props.data?.formatName ?? ""}</Badge>
					</OverlayTrigger>
				{
					isDebug && (
						<>
							<OverlayTrigger overlay={<Tooltip >Created At</Tooltip>}>
								<Badge bg="success" className='list-free-poster-item-createdAt-badge shadow'>{props.data?.createdAt ?? ""}</Badge>
							</OverlayTrigger>
							<OverlayTrigger overlay={<Tooltip >Update At</Tooltip>}>
								<Badge bg="success" className='list-free-poster-item-updatedAt-badge shadow'>{props.data?.updatedAt ?? ""}</Badge>
							</OverlayTrigger>
						</>
					)
				}
				</div>
				<div className="list-free-poster-item-img-wrapper">
                    <Card.Img 
                        variant="top" 
                        className="list-free-poster-item-img rounded-2" 
                        src={props.data?.thumbnailPreviewUrl}
                        alt={props.data?.name}
                    />
                </div>
				<div className={`list-free-poster-on-hover-items-container px-4 py-3`}>
					<div className="list-free-poster-on-hover-items-wrapper">
						<div className="header-items">
							<div className="list-free-poster-item-name text-white fw-bold">
								{props.data?.title}
							</div>
						</div>
						<div className="list-free-poster-item-action-buttons d-flex justify-content-around">
                        <NwCustomModal
							onShow={() => setDisplayPreview(true)}
							onClose={() => setDisplayPreview(false)}
                            customButton={<button className="btn btn-primary d-flex justify-content-center align-items-center"><Eye/></button>}
							modalProps={{
								body: {
									className: 'd-flex'
								}
							}}
						>
							<div className="d-flex">
								{displayPreview && <NwImage imgClassName="w-100 h-100 object-fit-contain " src={props.data?.imageMainUrl} />}
							</div>
						</NwCustomModal>
							<Link to={`/free_posters/editor/edit/${props.data.id}`} className="btn btn-primary d-flex justify-content-center align-items-center">
								<Pencil/>
								{/* { t('editButtonLabel2') } */}
							</Link>
							<Button 
								className="d-flex justify-content-center align-items-center"
								variant="primary"
								onClick={() =>{props.duplicateFreePoster(props.data.id)}}
							>
								<Files/>
								{/* { t('freePosters.duplicate') } */}
							</Button>
							<Button 
								variant="primary"
								className="d-flex justify-content-center align-items-center"
								onClick={() => {props.addToCart(props.data)}}
							>
								<NwCartIcon size={'1.1em'}/> 
								{/* { t('addToCartButtonLabel') } */}
								{
									props.addToCartLoading[props.id] ? (
										<span
											style={{ marginLeft: "10px" }}
											className="spinner-border spinner-border-sm"
											role="status"
											aria-hidden="true"
										></span>
									) : null
								}
							</Button>
							<Button 
								variant="primary"
								className="d-flex justify-content-center align-items-center"
								onClick={() => {props.deleteItem(props.data.id)}}
							>
								<Trash/> 
								{/* { t('deleteButtonLabel') } */}
							</Button>
						</div>
					</div>
				</div>
			</Card>
		</Col>
    )

}
export default FreePosterCard;