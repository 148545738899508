function AngleDoubleLeft({ size }: { size: number }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height={size} viewBox="0 0 24 24">
      <path
        d="M6.35592 11.7386L13.5034 4.59108L11.9123 3L4.76559 10.1475C4.27534 10.6386 4 11.3042 4 11.9981C4 12.6921 4.27534 13.3576 4.76559 13.8488L11.9123 21L13.5034 19.4089L6.35592 12.2577C6.3218 12.2236 6.29474 12.1832 6.27628 12.1386C6.25781 12.0941 6.24831 12.0463 6.24831 11.9981C6.24831 11.9499 6.25781 11.9022 6.27628 11.8576C6.29474 11.8131 6.3218 11.7726 6.35592 11.7386Z"
        fill="currentColor"
      />
      <path
        d="M20.9618 4.59108L19.3707 3L11.1677 11.2022C11.0632 11.3067 10.9802 11.4308 10.9236 11.5673C10.867 11.7039 10.8379 11.8503 10.8379 11.9981C10.8379 12.146 10.867 12.2923 10.9236 12.4289C10.9802 12.5655 11.0632 12.6895 11.1677 12.794L19.3707 21L20.9618 19.4089L13.5547 11.9981L20.9618 4.59108Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default AngleDoubleLeft
