import { getBackendApiUrl, getEasyPlvApiToken, getBackendUrl, getAuthTokenWithRefresh } from "../config";
import { checkApiResponse } from "./auth";
import {useState} from "react";


export const logActivity = async(data) => {

    let relatedEntity = '';
    let parentId = 0;
    const type = data.type;
    const parentType = data.parentType;
    if(parentType === "PrintOrderItemDownloadId"){
        relatedEntity = "printItemOrderDownload";
        parentId = data.parentId;
    }

    data = {
        "title": "print_pdf",
        "type": type,
        "relatedEntity": relatedEntity,
        "entityId": parentId
      };


    const res = await fetch(getBackendApiUrl(`activities/log`), {
      method: "POST",
      headers: {
        Authorization: `Bearer ${await getAuthTokenWithRefresh()}`,
        "X-EasyPLV-Api-Token": getEasyPlvApiToken(),
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    });
    checkApiResponse(res);
    const responseData  = await res.json();
    // console.log(responseData );
    return responseData ;
  
  }