import { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { getAuthTokenWithRefresh, getBackendApiUrl, getEasyPlvApiToken } from "../../config";
import { logoutUser } from "../../services/auth";
import { useTranslation } from "react-i18next";
import { Placeholder } from "react-bootstrap";
import { ArrowLeft } from "react-bootstrap-icons";
import { useMainLayoutContext } from "../../hooks/useMainLayoutContext";

function MessageDetail() {
  	const { t } = useTranslation();
    const [messageId, setId] = useState(useParams().id);
    const [data, setData] = useState([]);
	const apiKey = getEasyPlvApiToken();
	const [loading, setLoading] = useState(true);
	const { setPageTitleAdditionalContent } = useMainLayoutContext();
   
    useEffect(() => {
        async function getMessage() {
			try {
				const authToken = await getAuthTokenWithRefresh();
				const response = await fetch(getBackendApiUrl(`messages/${messageId}`),
					{
						method: "GET",
						headers: {
						Authorization: `Bearer ${authToken}`,
						"X-EasyPLV-Api-Token": apiKey,
						},
					}
				);
				if (response.status === 401) {
					logoutUser();
					return false;
				}
				const data = await response.json();
		
				console.log("Data received from the API: ", data);
				// console.log("Total Data: ", data.itemsCount);
				setData(data);
				setPageTitleAdditionalContent({
					classes: {
						pageTitleWrapper: 'with-message-subject',
						additionalContentWrapper: 'flex-column justify-content-center align-items-start nw-responsive-font-size-lg row-gap-0'
					},
					content: (
						<span className="nw-responsive-font-size-sm nw-text-placeholder-color-2">
							{data.subject}
						</span>
					)
				})
				setLoading(false);
			} catch (error) {
				console.error("Error fetching data: ", error);
				// Set loading state to false in case of an error
				setLoading(false);
			}
        }  
        getMessage();
	}, []);
	
    return (
        <div className="message-detail-page-container py-3 px-5">
			<div className="message-detail-page-wrapper d-flex flex-column row-gap-3">
				<div className="message-detail-page-header">
					<Link
						to={'/messages'}
						className="btn nw-bg-accent-1 text-white nw-responsive-font-size rounded-pill d-flex align-items-center fw-semibold"
						style={{
							width: 'fit-content',
						}}
					>
						<ArrowLeft className="me-1"/>{t("messagesPage.messagesTable.viewMessagePage.backToMessagesLabel")}
					</Link>
				</div>
				
				<div className="message-detail-page-content nw-font-open-sans">
					{
						loading ? (
							<>
								<Placeholder 
									as="div" 
									animation="glow"
									style={{
										height: '5ch'
									}}
								>
									<Placeholder className="h-100" xs={6} />
								</Placeholder>
								<Placeholder 
									as="div" 
									animation="glow"
									className="w-60"
								>
									<Placeholder xs={12} />
									<Placeholder xs={11} />
									<Placeholder xs={10} />
									<Placeholder xs={9} />
								</Placeholder>

							</>				
						) : (
							<>
								<div className="message-title fw-bold nw-responsive-font-size-xl nw-fade-in-animation">
									{data.subject}
								</div>
								<div className="message-body nw-text-placeholder-color-2 nw-fade-in-animation w-60 nw-text-prewrap">
									{data.body}
								</div>
							</>
						)
					}

				</div>
			</div>
		</div>
    )
}

export default MessageDetail;