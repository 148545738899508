import { getAuthTokenWithRefresh, getBackendApiUrl, getEasyPlvApiToken } from "../config"
import { checkApiResponse } from "./auth";
import { useEffect } from 'react';
import {logActivity} from "./activities";

export const getNotifications = async (options = {}) => {
    // do not allow refreshing of token before expiry as notifications are fetched every 5 seconds, will never log out.
    const authToken = await getAuthTokenWithRefresh(false);
    const apiKey = getEasyPlvApiToken();
    const notificationsPromise = await fetch(getBackendApiUrl('notifications/unseen'), {
        method : "GET",
        headers: {
            'Content-Type' : 'application/json',
            'Authorization' : `Bearer ${authToken}`,
            'X-EasyPLV-Api-Token': apiKey
        }
    }).catch(error => {
        console.error(error);
    }) 
    
    if (notificationsPromise === undefined) return {};
    checkApiResponse(notificationsPromise);
    if (notificationsPromise.status === 500) return {};
    
    const notificationsJsonData = await notificationsPromise.json();
    return notificationsJsonData;
}

export const seeNotification = async (notificationId) => {
    const authToken = await getAuthTokenWithRefresh();
    const apiKey = getEasyPlvApiToken();
    const seeNotificationPromise = await fetch (getBackendApiUrl(`notification/see/${notificationId}`), {
        method: 'GET',
        headers: {
            'Content-Type' : 'application/json',
            'Authorization' : `Bearer ${authToken}`,
            'X-EasyPLV-Api-Token': apiKey
        }
    }).catch(error => {console.error(error);});

    if (seeNotificationPromise.status === 200) {
        const data = await seeNotificationPromise.json();
        // console.log(data.notificationId);
        // console.log(data.seen);
        // console.log(data);
        if (
            data.notificationType === 'pdf_print_generation_success' ||
            data.notificationType === 'zip_generation_success' ||
            data.notificationType === 'pdf_download_generation_success'
        ) {
            console.log('in condition');
            await logActivity(data);
        }

        return data;
        // return await seeNotificationPromise.json();
    } else {
        checkApiResponse(seeNotificationPromise);
    }

    return {'seen' : false};
} 

export const getDailyNotifications = async () => {
    const authToken = await getAuthTokenWithRefresh();
    const apiKey = getEasyPlvApiToken();

    const getDailyNotificationsPromise = await fetch(getBackendApiUrl(`notifications/day`), {
        method : "GET",
        headers: {
            'Content-Type' : 'application/json',
            'Authorization' : `Bearer ${authToken}`,
            'X-EasyPLV-Api-Token': apiKey
        }
    }).catch(e => console.log(e));

    checkApiResponse(getDailyNotificationsPromise);

    if (getDailyNotificationsPromise?.status === 200) {
        return await getDailyNotificationsPromise.json();
    } else {
        return null;
    }
}

export const filterDailyNotifications = async (filters) => {
    try {
        const authToken = await getAuthTokenWithRefresh();
        const apiKey = getEasyPlvApiToken();

        let queryString = "?page=1"
        const pageQuery = filters?.page;
        if (pageQuery) queryString = `?page=${pageQuery}`;
    
        const getDailyNotificationsPromise = await fetch(getBackendApiUrl(`notifications/day`) + queryString, {
            method : "GET",
            headers: {
                'Content-Type' : 'application/json',
                'Authorization' : `Bearer ${authToken}`,
                'X-EasyPLV-Api-Token': apiKey
            }
        }).catch(e => console.log(e));
    
        checkApiResponse(getDailyNotificationsPromise);
    
        if (getDailyNotificationsPromise.status === 200) {
            return await getDailyNotificationsPromise.json();
        } else {
            return null;
        }
    } catch (e) {
        console.log(e);
        return;
    }
}