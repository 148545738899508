import { useState, useContext, useEffect } from "react";
import MainLayoutContext from "../../contexts/MainLayoutContext";
import { Link} from "react-router-dom";
import { NwLoaderSpinner } from "../../components/_utilityComponents/NwIcons/NwIcons";
import { useTranslation } from "react-i18next";
import { getStateDataByFrontState } from "../../services/operation";
import Table from "react-bootstrap/Table";
import { CircleFill } from "react-bootstrap-icons";
import { getAuthTokenWithRefresh, getBackendApiUrl, getEasyPlvApiToken } from "../../config";

const DashboardOperationList = ({}) => {
	const { t } = useTranslation();

	const [posts, setPosts] = useState([]);

	const [totalItems,setTotalItems] = useState(0);
	const [isloading, setisloading] = useState(true);

	const apiKey = getEasyPlvApiToken();
	const { locale } = useContext(MainLayoutContext);


	async function getOperations() {
		try {
			const authToken = await getAuthTokenWithRefresh();
			const response = await fetch(getBackendApiUrl("operation/byUser"), {
				method: "GET",
				headers: {
						Authorization: `Bearer ${authToken}`,
						"X-EasyPLV-Api-Token": apiKey,
						"nw-front-locale": locale || 'fr'
				},
			});
			setisloading(true);
			const data = await response.json();
			setisloading(false);
			if(data === undefined){
					return <p>No Operation...</p>;
			}
			// Set the state with the data
			
			setPosts(data.items);
			setTotalItems(data.totalItems);

			// Set loading state to false after fetching
		} catch (error) {
			console.error("Error fetching data: ", error);
		}
	}

	const getFrontStateData = (frontState, published = false) => {
		const stateData = getStateDataByFrontState(frontState);
		return {
			displayText: published ? t(`dashboard.operationFrontState.published`) : t(stateData.translationKey),
			color: published ? "success" : stateData.stateColor
		};
	};
		
	useEffect(() => {
		getOperations();
	}, []);

	return (
		<div className="h-100 bg-white shadow-lg rounded-3 px-3 py-3 d-flex flex-column w-100">
			<div
				className="mb-xxl-2"
				style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "space-between",
					height: '10%'
				}}
			>
				<span className="nw-responsive-font-size ps-2 fw-bold">
					{t("dashboard.operationTitle")}
					{isloading ? (<NwLoaderSpinner isSpinner size={'1em'} className="ms-1 nw-responsive-font-size"/>) : null}
				</span>
				<Link
					to={'/operations'}
					className=" text-decoration-none text-primary fw-bold nw-responsive-font-size rounded-pill d-flex align-items-center fw-semibold py-0 pe-2"
					style={{
						width: 'fit-content',
						height: 'fit-content'
					}}
				>
						{t("dashboard.messages.viewAll")}
				</Link>
			</div>
			<div className="home-operations-list-container flex-fill overflow-auto px-3">
				<Table >
					<thead className="sticky-top">
						<tr>
							<th className="nw-text-placeholder-color-2 fw-semibold" style={{ backgroundColor: '#FAFAFB' }}>{t("dashboard.tableHeaderName.name")}</th>
							<th className="nw-text-placeholder-color-2 fw-semibold" style={{ backgroundColor: '#FAFAFB' }}>{t("dashboard.tableHeaderName.code")}</th>
							<th className="nw-text-placeholder-color-2 fw-semibold" style={{ backgroundColor: '#FAFAFB' }}> {t("dashboard.tableHeaderName.startDate")}</th>
							<th className="nw-text-placeholder-color-2 fw-semibold" style={{ backgroundColor: '#FAFAFB' }}>{t("dashboard.tableHeaderName.status")}</th>
						</tr>
					</thead>
					<tbody>
						{posts?.map((post, index) => (
							<tr key={index}>
								<td className="fw-semibold">{post.published ? <Link className="text-decoration-none text-primary" to={`/operation/${post.id}/products`}>{post.name}</Link> : post.name}</td>
								<td>{post.code}</td>
								<td>{post.formatedFrontDateDisplay}</td>
								<td>
									<div className="d-flex align-items-center column-gap-1">
										<CircleFill 
											className={`nw-responsive-font-size-xs text-${getFrontStateData(post.stateFront, post.published).color}`}
										/>
										<span>{getFrontStateData(post.stateFront, post.published).displayText}</span>
									</div>									
								</td>
							</tr>
						))}
						
					</tbody>
				</Table>
			</div>
		</div>
	);
};

export default DashboardOperationList;
