import { useState, useEffect } from "react";
import { nwMediaQueries, PlatformTypes } from "../constants/nw/nw-constants";

/**
 * Custom hook that determines and tracks the current platform based on screen size changes and media queries.
 * It utilizes the media queries defined in `nwMediaQueries` and updates the current platform dynamically as the window resizes.
 * 
 * @returns {PlatformTypes} The current platform key (e.g., PlatformTypes.IS_MOBILE, PlatformTypes.IS_TABLET, etc.) based on the matching media query.
 * 
 * @example
 * const platform = useCurrentPlatform();
 * console.log(platform); // Logs PlatformTypes.IS_MOBILE, PlatformTypes.IS_TABLET, etc. based on screen size
 */
export function useCurrentPlatform() {
    const [currentPlatform, setCurrentPlatform] = useState(PlatformTypes.IS_LARGE_DESKTOP);

    useEffect(() => {
        const updateCurrentPlatform = () => {
            const matches = Object.entries(nwMediaQueries).filter(([key, query]) => {
                return window.matchMedia(query).matches;
            });

            if (matches.length > 0) {
                setCurrentPlatform(matches[0][0]);
            }
        };

        // Initial check on mount
        updateCurrentPlatform();

        // Event listener for screen size changes
        window.addEventListener('resize', updateCurrentPlatform);

        // Cleanup function (remove the listener when the component unmounts)
        return () => window.removeEventListener('resize', updateCurrentPlatform);
    }, []);

    return currentPlatform;
}

/**
 * The function `useTouchDevice` determines whether the user's device is a touch device or not using
 * JavaScript.
 * @returns The `useTouchDevice` function returns a boolean value indicating whether the current device
 * is a touch device or not.
 */
export function useTouchDevice() {
    const [isTouchDevice, setIsTouchDevice] = useState(false);
  
    useEffect(() => {
      const checkTouchDevice = () => (
        'ontouchstart' in window ||
        navigator.maxTouchPoints > 0 ||
        navigator.msMaxTouchPoints > 0
      );
  
      setIsTouchDevice(checkTouchDevice());
    }, []);
  
    return isTouchDevice;
};

/**
 * The `useFlexWrapStatus` function in JavaScript is a custom hook that determines whether the content
 * inside a container is wrapping based on the container's height and the height of its children.
 * @param containerRef - The `containerRef` parameter is a reference to the container element that you
 * want to monitor for changes in its wrapping status. This reference is typically created using
 * React's `useRef` hook and then passed to the `useFlexWrapStatus` custom hook to track changes in the
 * container's wrapping behavior
 * @returns The `useFlexWrapStatus` custom hook returns a boolean value `isWrapping`, which indicates
 * whether the content inside the container is wrapping or not based on the comparison of container
 * height and child height.
 */
export function useFlexWrapStatus (containerRef) {
    const [isWrapping, setIsWrapping] = useState(false);
  
    const checkWrap = () => {
        if (containerRef.current && containerRef.current.children.length > 0) {
            const style = window.getComputedStyle(containerRef.current);
            const flexDirection = style.flexDirection;
            let isWrappingDetected = false;
      
            if (flexDirection === 'row' || flexDirection === 'row-reverse') {
              const containerHeight = containerRef.current.offsetHeight;
              const childHeight = containerRef.current.children[0].offsetHeight;
              isWrappingDetected = containerHeight > childHeight;
            } else if (flexDirection === 'column' || flexDirection === 'column-reverse') {
              const containerWidth = containerRef.current.offsetWidth;
              const childWidth = containerRef.current.children[0].offsetWidth;
              isWrappingDetected = containerWidth > childWidth;
            }
      
            setIsWrapping(isWrappingDetected);
        }
    };
  
    useEffect(() => {
      // Check wrap status on mount and update the status'
        checkWrap();
    
        // Add event listeners to re-check on window resize or content changes
        window.addEventListener('resize', checkWrap);
    
        // MutationObserver to observe child list changes in the container
        const observer = new MutationObserver(checkWrap);
        if (containerRef.current && containerRef.current.children.length > 0) {
            observer.observe(containerRef.current, { childList: true });
        }
      // Cleanup
      return () => {
        window.removeEventListener('resize', checkWrap);
        observer.disconnect();
      };
    }, [containerRef]); // Re-run the effect if the containerRef changes
  
    return isWrapping;
};