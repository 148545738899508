function Delete({ size }: { size: number }) {
  return (
    <svg height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19 6H15.25V4.5C15.25 4.10218 15.092 3.72064 14.8107 3.43934C14.5294 3.15804 14.1478 3 13.75 3H9.25C8.85218 3 8.47064 3.15804 8.18934 3.43934C7.90804 3.72064 7.75 4.10218 7.75 4.5V6H4V7.5H5.5V18.75C5.5 19.3467 5.73705 19.919 6.15901 20.341C6.58097 20.7629 7.15326 21 7.75 21H15.25C15.8467 21 16.419 20.7629 16.841 20.341C17.2629 19.919 17.5 19.3467 17.5 18.75V7.5H19V6ZM9.25 4.5H13.75V6H9.25V4.5ZM16 18.75C16 18.9489 15.921 19.1397 15.7803 19.2803C15.6397 19.421 15.4489 19.5 15.25 19.5H7.75C7.55109 19.5 7.36032 19.421 7.21967 19.2803C7.07902 19.1397 7 18.9489 7 18.75V7.5H16V18.75Z"
        fill="currentColor"
      />
      <path d="M10.75 10.5H9.24999V16.5H10.75V10.5Z" fill="currentColor" />
      <path d="M13.75 10.5H12.25V16.5H13.75V10.5Z" fill="currentColor" />
    </svg>
  )
}

export default Delete
