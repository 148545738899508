import useSocialMediaContext from "../../../hooks/useSocialMediaContext";
import NwCustomSelect from "../../../../../components/_utilityComponents/NwFormsUi/CustomSelect/NwCustomSelect";
import NwCustomInput from "../../../../../components/_utilityComponents/NwFormsUi/CustomInput/NwCustomInput";
import PreviewList from "../../../../../components/Posters/PreviewList/PreviewList";
import { handleSearchData, handleSortData, handleFetchMoreData } from "../../../../../services/posters";
import { getSocialMediaTemplateGroups } from "../../../../../services/social_media";

import React, { useState } from 'react'
import { useTranslation } from "react-i18next";

export default function TemplateGroupModels() { 
    const { t } : any = useTranslation();
    const [ searchValue, setSearchValue ] = useState<string | undefined | null>('');

    const { 
        socialMediaTemplateGroups,
        setSocialMediaTemplateGroups,
        currentSelectedTemplateGroup,
        setCurrentSelectedTemplateGroup,
        templateGroupBodyData,
        setTemplateGroupBodyData,
        socialMediaThemeTypes,
        currentSelectedThemeType,
        setCurrentSelectedThemeType,
        socialMediaCampaigns,
        currentSelectedSocialMediaCampaign,
        setCurrentSelectedSocialMediaCampaign,
    } = useSocialMediaContext();

    const handleTemplateGroupSelection = async (params : any) => {
        if (params) {
            const templateGroup = params;
            setCurrentSelectedTemplateGroup(templateGroup);
        }
    }
    
    const handleSearchTemplateGroup = async () => {
        const newTemplateGroupBodyData = {...templateGroupBodyData}
        newTemplateGroupBodyData.options.page = 1;
        newTemplateGroupBodyData.filters.name = searchValue;

        setTemplateGroupBodyData(newTemplateGroupBodyData);
    }

    return (
        <div className="social-media-template-group-models w-100 h-100 pt-3">
            <div className="tab-header mb-4 mt-2 nw-font-open-sans">
                { t('freeEditorSocialMedia.tabs.templateGroupModels.header') }
            </div>
            <div className="search-container my-3">
                <NwCustomInput 
                    isSearch hideLabel
                    placeholder={t('freeEditorSocialMedia.tabs.templateGroupModels.dressingSearch')}
                    value={searchValue} 
                    onChange={(e: any) => setSearchValue(e.target.value)}
                    buttonOnClick={handleSearchTemplateGroup}
                />
            </div>
            <div className="mb-2">{t('freeEditorSocialMedia.tabs.templateGroupModels.filter')}</div>
            <div className="filters-container d-flex flex-column row-gap-3">
                <NwCustomSelect
                    // @ts-ignore
                    isClearable
                    value={currentSelectedThemeType}
                    isDisabled={socialMediaThemeTypes.length <= 0}
                    options={socialMediaThemeTypes}
                    placeholder={t('freeEditorSocialMedia.tabs.templateGroupModels.seasonalThemes')}
                    onChange={(params: any) => {setCurrentSelectedThemeType(params)}}
                />
                <div className="general-theme-filter-container">
                    <NwCustomSelect
                        // @ts-ignore
                        isClearable
                        value={currentSelectedSocialMediaCampaign}
                        options={socialMediaCampaigns}
                        isDisabled={socialMediaCampaigns.length <= 0}
                        placeholder={t('freeEditorSocialMedia.tabs.templateGroupModels.generalThemes')}
                        onChange={(params: any) => {setCurrentSelectedSocialMediaCampaign(params)}}
                        uiOverrides={{
                            border: {
                                control: {
                                    borderRadius : currentSelectedSocialMediaCampaign ? '.3rem .3rem 0rem 0rem' : '.3rem'
                                }
                            }
                        }}
                    />
                    <div className={`filter-preview-list-container ${currentSelectedSocialMediaCampaign ? '' : 'd-none'}`}>
                        <PreviewList
                            listData={socialMediaTemplateGroups}
                            getCurrentSelected={handleTemplateGroupSelection}
                            initialSelection={currentSelectedTemplateGroup}
                            updateListData={setSocialMediaTemplateGroups}
                            bodyData={templateGroupBodyData}
                            bodyDataUpdator={setTemplateGroupBodyData}
                            handleSortData={handleSortData}
                            fetchSearchData={handleSearchData}
                            fetchMoreData={handleFetchMoreData}
                            getListData={getSocialMediaTemplateGroups}
                            hideSearch
                            hasFilter
                        />
                    </div>  
                </div>              
            </div>
        </div>
    )
}